import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { TimetableDataList } from '../models/timetable.model';
import { PGAvailabilitySelection, PGBookingsCountData, PGUserContact, PgAvailabilitySelectData, PgBookingsCountDayData } from '../models/booking.model';
import { PGUtilities } from '../pg-utilities';

export type CustomerCareBookingStatus = 'pending'|'canceled'|'available'|'confirmed';

export class CustomerCareBookingData {
	id: string
	customer_care_id: number
    user_name: string
	user_phone: string
	user_email: string
	name_app: string
	date: string
	start: string
	end: string
	pid: string
    totem: string
	status: CustomerCareBookingStatus
    start_datetime: string
    end_datetime: string
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCareService {

    constructor(private http:HttpClient, private environmentService:EnvironmentService) { }

    getAvailability() {
        return new Observable<Array<PgAvailabilitySelectData>>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/public/availability/app').subscribe((data:any) => {
                let _retIndex:{ [id:string]: PgAvailabilitySelectData } = {}

                for(let _data of data) {
                    let _id = _data.user.toString();
                    if(_data.timezone == null) _data.timezone = 'Europe/Rome';
                    
                    if(_retIndex[_id] == null) {
                        _retIndex[_id] = {
                            id: _id,
                            data: _data.customer_care,
                            capacity: 1,
                            languages: _data.languages,
                            availability: new TimetableDataList(_data)
                        }
                    }
                }

                let _retVal:Array<PgAvailabilitySelectData> = []

                for(let i in _retIndex) {
                    _retVal.push(_retIndex[i])
                }

                observer.next(_retVal)
                observer.unsubscribe();
            }, () => {
                observer.next(null)
                observer.unsubscribe();
            })
        })
    }

    private _bookingsCountDateToString(date:Date) {
        let _dateString = date.getFullYear().toString() + '-'
        if(date.getMonth() < 9) _dateString += '0'
        _dateString += (date.getMonth() + 1) + '-'
        if(date.getDate() < 9) _dateString += '0'
        _dateString += date.getDate();

        return _dateString;
    }

    getBookingsCountDay(user:string, year:number, month:number, day:number) {
        return new Observable<PgBookingsCountDayData>((observer) => {
            let _reqDate = new Date(year, month - 1, day);
            let _reqString = this._bookingsCountDateToString(_reqDate);

            this.http.get(this.environmentService.environment.APIUrl + '/public/count/userBooking/' + user + '?from=' + _reqString + '&to=' + _reqString, {
                headers:  { 'X-Interceptor-Silent': '404' }
            }).subscribe((data) => {
                let _retVal:PgBookingsCountDayData = {};
                
                for(let _cData of data as Array<any>) {
                    let _cYear = parseInt(_cData.date.split('-')[0]);
                    let _cMonth = parseInt(_cData.date.split('-')[1]);
                    let _cDay = parseInt(_cData.date.split('-')[2]);

                    if(_cYear == year && _cMonth == month && _cDay == day) {
                        _retVal[_cData.start.match(/\d\d:\d\d/)[0]] = _cData.total;
                    }
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getBookingsDay(user:string, year:number, month:number, day:number) {
        return new Observable<{ [time:string]: CustomerCareBookingData}>((observer) => {
            let _reqDate = new Date(year, month - 1, day);
            let _reqString = this._bookingsCountDateToString(_reqDate);

            this.http.get(this.environmentService.environment.APIUrl + '/public/count/userBooking/' + user + '?from=' + _reqString + '&to=' + _reqString, {
                headers:  { 'X-Interceptor-Silent': '404' }
            }).subscribe((data) => {
                let _retVal:{ [time:string]: CustomerCareBookingData} = {};
                
                for(let _item of data as Array<any>) {
                    let _year = parseInt(_item.date.split('-')[0]);
                    let _month = parseInt(_item.date.split('-')[1]);
                    let _day = parseInt(_item.date.split('-')[2]);

                    if(_year == year && _month == month && _day == day) {
                        _retVal[_item.start.match(/\d\d:\d\d/)[0]] = _item;
                    }
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getBookingsCount(user:string, from:Date, to:Date) {
        return new Observable<PGBookingsCountData>((observer) => {

            let _toExcl = new Date(to.getTime() - 1)
            let _reqTo = new Date(_toExcl.getFullYear(), _toExcl.getMonth(), _toExcl.getDate())

            let _fromString = this._bookingsCountDateToString(from)
            let _toString = this._bookingsCountDateToString(_reqTo)

            this.http.get(this.environmentService.environment.APIUrl + '/public/count/userBooking/' + user + '?from=' + _fromString + '&to=' + _toString, {
                headers:  { 'X-Interceptor-Silent': '404' }
            }).subscribe((data) => {
                let _retVal:PGBookingsCountData = {};
                
                for(let _cData of data as Array<any>) {
                    let _cYear = parseInt(_cData.date.split('-')[0]);
                    let _cMonth = parseInt(_cData.date.split('-')[1]);
                    let _cDay = parseInt(_cData.date.split('-')[2]);
                    let _cTime = _cData.start.match(/\d\d:\d\d/)[0]

                    let _cDate = new Date(_cYear, _cMonth - 1, _cDay, parseInt(_cTime.split(':')[0]), parseInt(_cTime.split(':')[1]))

                    if(_cDate.getTime() >= from.getTime() && _cDate.getTime() < to.getTime()) {
                        if(_retVal[_cYear] == null) {
                            _retVal[_cYear] = {};
                        }
    
                        if(_retVal[_cYear][_cMonth] == null) {
                            _retVal[_cYear][_cMonth] = {};
                        }
    
                        if(_retVal[_cYear][_cMonth][_cDay] == null) {
                            _retVal[_cYear][_cMonth][_cDay] = {};
                        }
    
                        _retVal[_cYear][_cMonth][_cDay][_cTime] = 1;
                    }
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    createBooking(user:string, selectedLanguage:string, selectedAvailability:PGAvailabilitySelection, userContact:PGUserContact, totem:string, additionalInformations:string) {
        return new Observable<CustomerCareBookingData>((observer) => {
            this.http.post(this.environmentService.environment.APIUrl + '/public/userBooking', {
                user_id: user,
                language: selectedLanguage,
                availability: selectedAvailability,
                user_name: userContact?.name,
                user_email: PGUtilities.cleanRecipient(userContact?.email, 'email'),
                user_phone: PGUtilities.cleanRecipient(userContact?.phone, 'phone'),
                totem: totem,
                additional_info: additionalInformations
            }, {
                headers: { 'X-Interceptor-Silent': '409' }
            }).subscribe((data:CustomerCareBookingData) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getBooking(id:string) {
        return new Observable<CustomerCareBookingData>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/public/userBooking/' + id).subscribe((data:CustomerCareBookingData) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    confirmBooking(id:string) {
        return new Observable<CustomerCareBookingData>((observer) => {
            this.http.put(this.environmentService.environment.APIUrl + '/public/userBooking/confirm/' + id, { status: 'confirmed' }).subscribe((data:CustomerCareBookingData) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }
}