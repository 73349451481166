import { Subject } from "rxjs"
import { EFDataService } from "../services/ef-data.service"
import { LocalizationService } from "../services/localization.service"
import { TicketsData } from "./tickets.model"
import { TimetableDataList } from "./timetable.model"
import { PGUtilities } from "../pg-utilities"
import { PGLocation } from "./map.model"

export class EFProductMenu {
    menu_name: { [language:string]: string }
    menu_description: { [language:string]: string }

    menu_price: number
    menu_price_unit: string
    languages: Array<string>
}

export class EFProductAccommodation {
    accommodation_appartaments_services: Array<string>
    accommodation_description: { [language:string]: string }
    accommodation_room_services: Array<string>
    accommodation_size: number
    accommodation_type: string
    accommodation_prices: TicketsData
    cost_range: Array<number>
    languages: Array<string>
}

export class EFProductStoreShowcase {
    showcase_title:string
    showcase_image:string
    showcase_description:string
}

export type EFProductType = 'experience'|'host'|'eatery'|'poi'|'event'|'utility'|'itinerary'|'article';

export class EFProductAnalytics {
    id: string
    resource: string
    type?: string
    origin?: string
}

export class EFProduct {
    id: string = null;
    resource:string = null;
    element:string = null;
    type: EFProductType = null;
	title: string = null;
	category:string = null;
    subcategory:string = null;
	tags:Array<string> = null;
	description: string = null;
    languages:Array<string> = null;
	cost_range: number = null;
	target: string = null;
    cover:string = null;
	videos: Array<string> = [];
	images: Array<string> = [];
	province: string = null;
	address: string = null;
	city: string = null;
	zipcode: string = null;
	geolocation: PGLocation = null;
    certificate:boolean = false;
    supplier:string = null;
    poi_id:string = null;
    tour_product_id:string = null;
    
    email:string = null;
    phone:string = null;
    landline_number:string = null;
    website:string = null;

    dates:Array<string|{ begin: string, end: string }> = null;
    dates_days:Array<string|{ begin: string, end: string, days:string }> = null;
    duration:string = null;
    travel_time:string = null;
    travel_distance:string = null;
    travel_stops:number = null;
    mean_of_transport:Array<EFItineraryMeanOfTransport> = null;

    whats_included: string = null;
    availability:TimetableDataList = null;
    tickets:TicketsData = null;

    stars: number = null;
    services_description: string = null;
    accommodations:Array<EFProductAccommodation> = null;

    cuisine_type: Array<string> = null;
    special_dishes: string = null;
    menu:Array<EFProductMenu> = null;
    menu_file:string = null;
    menu_file_2:string = null;

    store_showcase:Array<EFProductStoreShowcase> = null;

    user_id:string = null;
    group_id:string = null;

    translations:{
        [lang:string]: any
    } = {}

    url:string = null;
    base_api:string = null;

    published:boolean = true;
    bookable:boolean = false;
    
    booking:string = null;
    payment:Array<string> = null;

    parent:string = null;

    error?:boolean = null;

    analytics?:EFProductAnalytics = null;

    cost_list?:Array<number> = null;
    services?:{ [type:string]: Array<string> } = null;

    private _getMinMaxTickets(tickets:TicketsData, minMax?:Array<number>) {
        if(tickets != null) {
            let _min = tickets.fullPrice;
            let _max = tickets.fullPrice;
    
            if(minMax != null) {
                if(_min  == null || minMax[0] < _min) _min = minMax[0];
    
                let _lastI = minMax.length - 1;
                if(_max == null || minMax[_lastI] > _max) _max = minMax[_lastI];
            }
    
            for(let _other of tickets.otherTickets) {
                if(_other.price != null) {
                    if(_min == null || _other.price < _min) _min = _other.price;
                    if(_max == null || _other.price > _max) _max = _other.price;
                }
            }
    
            if(_min == _max) {
                return [_min]
            }
            else {
                return [_min, _max]
            }
        }
    }

    constructor(private localizationService:LocalizationService, productType:EFProductType, fromData?:any, fromResource?:string, analytics?:EFProductAnalytics) {
        this.type = productType;

        this.analytics = analytics;

        if(fromData != null && fromResource != null) {
            this.resource = fromResource;

            for(let i in fromData) {
                if(i != 'type' && i != 'resource') {
                    if(i == 'id') {
                        this.element = fromData.id;
                        this.id = fromResource + '_' + fromData.id;
                    }
                    else if(i == 'tags') {
                        if(typeof fromData[i] == 'string') {
                            this[i] = fromData[i].toLowerCase().split(/\s*,\s*/)
                        }
                    }
                    else if(i == 'slot_duration') {
                        if(fromData[i] != null) this.duration = fromData[i].replace(/:00$/, '');
                    }
                    else if(i == 'translations') {
                        for(let _cTranslation of fromData[i]) {
                            this.translations[_cTranslation.language] = {}
    
                            for(let j in _cTranslation) {
                                if(j != 'language' && j != 'id' && j != fromResource + '_id' && j != 'created_at' && j != 'updated_at' && j != 'deleted_at') {
                                    this.translations[_cTranslation.language][j] = _cTranslation[j]
                                }
                            }
                        }
                    }
                    else {
                        let _cProp = i;
                        let _cVal = fromData[i];

                        if(_cProp == 'languages') {
                            this[_cProp] = PGUtilities.tryParseJSON(_cVal);
                        }
                        else if(_cProp == 'availability') {
                            let _cObj = PGUtilities.tryParseJSON(_cVal);
                            
                            if(_cObj != null) {
                                this.availability = new TimetableDataList(_cObj)
                            }
                        }
                        else if(/^images/.test(_cProp) || _cProp == 'videos') {
                            if(_cVal != null) {
                                if(/^images/.test(_cProp)) {
                                    _cProp = 'images'
                                }
    
                                let _cObj = PGUtilities.tryParseJSON(_cVal);

                                if(_cObj != null) {
                                    if(this[_cProp] == null) {
                                        this[_cProp] = _cObj
                                    }
                                    else {
                                        for(let _item of _cObj) {
                                            this[_cProp].push(_item)
                                        }
                                    }
                                }
                            }
                        }
                        else if(/_services$/.test(_cProp) && !/^flag_/.test(_cProp)) {
                            if(_cVal != null) {
                                if(this.services == null) this.services = {};
                                this.services[_cProp] = _cVal;
                            }
                        }
                        else if (_cProp == 'coordinates') {
                            if(fromData.geolocation == null) {
                                this.geolocation = _cVal;
                            }
                        }
                        else if(typeof this[_cProp] != 'undefined') {
                            this[_cProp] = _cVal
                        }
                    }
                }
            }

            this.bookable = (productType == 'experience' || productType == 'eatery' || productType == 'host') && fromData.bookable != false;
        }

        if(this.tickets != null) {
            this.tickets = PGUtilities.tryParseJSON(this.tickets);

            this.cost_list = [this.tickets.fullPrice]
        }

        if(this.accommodations != null) {
            for(let _accommodation of this.accommodations) {
                _accommodation.accommodation_appartaments_services = PGUtilities.tryParseJSON(_accommodation.accommodation_appartaments_services)
                _accommodation.accommodation_room_services = PGUtilities.tryParseJSON(_accommodation.accommodation_room_services)
                _accommodation.accommodation_description = PGUtilities.tryParseJSON(_accommodation.accommodation_description)
                _accommodation.accommodation_prices = PGUtilities.tryParseJSON(_accommodation.accommodation_prices)

                _accommodation.cost_range = this._getMinMaxTickets(_accommodation.accommodation_prices)

                if(_accommodation.accommodation_prices) {
                    if(this.cost_list == null) this.cost_list = [];
                    
                    this.cost_list.push(_accommodation.accommodation_prices.fullPrice)
                }
            }
        }

        if(this.menu != null) {
            for(let _item of this.menu) {
                _item.menu_name = PGUtilities.tryParseJSON(_item.menu_name)
                _item.menu_description = PGUtilities.tryParseJSON(_item.menu_description) 
            }
        }

        if(this.store_showcase != null) {
            this.store_showcase = PGUtilities.tryParseJSON(this.store_showcase)
        }

        if(this.cost_list != null) {
            this.cost_list.sort((a, b) => {
                if(a < b) return -1;
                else if(a > b) return 1;
                else return 0;
            })
        }

        if(this.mean_of_transport != null) {
            let _val:any = this.mean_of_transport;

            try {
                this.mean_of_transport = JSON.parse(_val);
            }
            catch(ex) {
                if(_val != '') {
                    this.mean_of_transport = [_val]
                }
            }
        }
    }

    getProductFieldLanguage(prop:string) {
        if(this.translations != null) {
            if(this.translations[this.localizationService.currentLanguage] != null && this.translations[this.localizationService.currentLanguage][prop] != null) return this.localizationService.currentLanguage;
            else if(this.translations['en_EN'] != null && this.translations['en_EN'][prop] != null) return 'en_EN';
            else {
                for(let _language of this.localizationService.availableApplicationLanguages) {
                    if(this.translations[_language] != null && this.translations[_language][prop] != null) return _language;
                }
            }

            if(this[prop] != null) {
                return ''
            }
            else return null
        }
        else if(this[prop] != null) {
            return ''
        }
        else return null
    }

    getTranslatedProductField(prop:string) {
        if(this._forcedLanguage == null && this.getRemoteTranslationStatus(prop) == 'success') return this._remoteTranslations[this.localizationService.currentLanguage].props[prop].value;
        else {
            let _propertyLanguage = this.getProductFieldLanguage(prop)
            if(_propertyLanguage == null) return '';
            else if(_propertyLanguage == '') return this[prop];
            else return this.translations[this._forcedLanguage || _propertyLanguage][prop];
        }
    }

    getProductFieldArrayLanguage(field:string, index:number, prop:string) {
        let _cVal = this[field][index][prop]

        if(_cVal != null) {
            if(typeof _cVal == 'string') return '';
            else {
                if(_cVal[this.localizationService.currentLanguage] != null) return this.localizationService.currentLanguage;
                else if(_cVal['en_EN'] != null) return 'en_EN'
                else {
                    for(let _language of this.localizationService.availableApplicationLanguages) {
                        if(_cVal[_language] != null) return _language;
                    }
                }
            }
        }
    }

    getTranslatedProductFieldArrayProperty(field:string, index:number, prop:string) {
        if(this._forcedLanguage == null && this.getRemoteTranslationStatus(field, index, prop) == 'success') return this._remoteTranslations[this.localizationService.currentLanguage].props[field].items[index][prop].value;
        else {
            let _propertyLanguage = this.getProductFieldArrayLanguage(field, index, prop)
            if(_propertyLanguage == null) return '';
            else if(_propertyLanguage == '') return this[field][index][prop];
            else return this[field][index][prop][this._forcedLanguage || _propertyLanguage];
        }
    }
    
    private _remoteTranslateFields = ['title','description','services_description','special_dishes','whats_included']
    private _remoteTranslateFieldsArray = [
        { field: 'menu', props: [ 'menu_name', 'menu_description' ] },
        { field: 'accommodations', props: [ 'accommodation_description' ] }
    ]

    hasProductCurrentLanguage() {
        if(this.translations == null || this.translations[this.localizationService.currentLanguage] == null) return false;
        else {
            for(let _prop of this._remoteTranslateFields) {
                let _propertyLanguage = this.getProductFieldLanguage(_prop)
                if(_propertyLanguage != null && _propertyLanguage != '' && _propertyLanguage != this.localizationService.currentLanguage) return false;
            }

            for(let _array of this._remoteTranslateFieldsArray) {
                if(this[_array.field] != null) {
                    for(let i = 0; i < this[_array.field].length; i++) {
                        for(let _prop of _array.props) {
                            let _propertyLanguage = this.getProductFieldArrayLanguage(_array.field, i, _prop)
                            if(_propertyLanguage != null && _propertyLanguage != '' && _propertyLanguage != this.localizationService.currentLanguage) return false;
                        }
                    }
                }
            }

            return true;
        }
    }

    private _remoteTranslations:{ 
        [lang:string]: {
            status: 'loading'|'complete',
            props: {
                [prop:string]: {
                    status: 'loading'|'error'|'success'|'complete',
                    value?: string
                    items?: Array<{
                        [prop:string]: {
                            status: 'loading'|'error'|'success',
                            value?: string
                        }
                    }>
                }
            }
        }
    } = {}

    getRemoteTranslationStatus(field?:string, index?:number, prop?:string) {
        let _remoteTranslations = this._remoteTranslations[this.localizationService.currentLanguage];
        if(_remoteTranslations != null) {
            if(field == null) return _remoteTranslations.status;
            else {
                if(_remoteTranslations.props[field] != null) {
                    if(index == null) return _remoteTranslations.props[field].status;
                    else {
                        if(_remoteTranslations.props[field].items != null && _remoteTranslations.props[field].items[index] != null && _remoteTranslations.props[field].items[index][prop] != null)
                        return _remoteTranslations.props[field].items[index][prop].status
                    }
                }
            }
        }
    }

    remoteTranslateProduct() {
        this._remoteTranslations[this.localizationService.currentLanguage] = { status: 'loading', props: {} };
        let _remoteTranslations = this._remoteTranslations[this.localizationService.currentLanguage]

        let _reqCount = 0;

        for(let _prop of this._remoteTranslateFields) {
            let _language = this.getProductFieldLanguage(_prop)
            if(_language != null && _language != '') {
                _remoteTranslations.props[_prop] = { status: 'loading' }

                _reqCount++;
                this.localizationService.remoteTranslate(this.translations[_language][_prop], this.localizationService.currentLanguage, _language).subscribe((val) => {
                    _remoteTranslations.props[_prop] = { status: 'success', value: val }

                    _reqCount--
                    if(_reqCount == 0) {
                        _remoteTranslations.status = 'complete'
                    }
                }, () => {
                    _remoteTranslations.props[_prop] = { status: 'error' }

                    _reqCount--
                    if(_reqCount == 0) {
                        _remoteTranslations.status = 'complete'
                    }
                })
            }
        }

        for(let _array of this._remoteTranslateFieldsArray) {
            if(this[_array.field] != null) {
                _remoteTranslations.props[_array.field] = { status: 'loading', items: [] }

                // TODO: tutta questa roba può essere smontata usando this.localizationService.remoteTranslateAll 

                let _arrayReqCount = 0;

                for(let i = 0; i < this[_array.field].length; i++) {
                    _remoteTranslations.props[_array.field].items[i] = {}

                    for(let _prop of _array.props) {
                        let _language = this.getProductFieldArrayLanguage(_array.field, i, _prop)
                        if(_language != null && _language != '') {
                            _remoteTranslations.props[_array.field].items[i][_prop] = { status: 'loading' }

                            _arrayReqCount++;
                            _reqCount++;
                            this.localizationService.remoteTranslate(this[_array.field][i][_prop][_language], this.localizationService.currentLanguage, _language).subscribe((val) => {
                                _remoteTranslations.props[_array.field].items[i][_prop] = { status: 'success', value: val }
            
                                _arrayReqCount--;
                                if(_arrayReqCount == 0) {
                                    _remoteTranslations.props[_array.field].status = 'complete'
                                }

                                _reqCount--;
                                if(_reqCount == 0) {
                                    _remoteTranslations.status = 'complete'
                                }
                            }, () => {
                                _remoteTranslations.props[_array.field].items[i][_prop] = { status: 'error' }
            
                                _arrayReqCount--;
                                if(_arrayReqCount == 0) {
                                    _remoteTranslations.props[_array.field].status = 'complete'
                                }

                                _reqCount--;
                                if(_reqCount == 0) {
                                    _remoteTranslations.status = 'complete'
                                }
                            })
                        }
                    }
                }

                if(_arrayReqCount == 0) {
                    _remoteTranslations.props[_array.field].status = 'complete'
                }
            }
        }

        if(_reqCount == 0) {
            _remoteTranslations.status = 'complete'
        }
    }

    unRemoteTranslateProduct() {
        if(this._remoteTranslations[this.localizationService.currentLanguage] != null) this._remoteTranslations[this.localizationService.currentLanguage] = null;
    }

    private _forcedLanguage:string = null;

    isCurrentTranslationAutomatic() {
        let _language = this._forcedLanguage || this.localizationService.currentLanguage;
        return this._remoteTranslations[_language] != null || (this.translations[_language] != null && this.translations[_language].auto);
    }

    forceNonAutomaticTranslation() {
        for(let i in this.translations) {
            if(!this.translations[i].auto) {
                this._forcedLanguage = i;
                break;
            }
        }
    }

    isCurrentTranslationForced() {
        return this._forcedLanguage != null;
    }

    unForceTranslation() {
        this._forcedLanguage = null;
    }

    getTypeText() {
        return this.localizationService.translate('experience-finder.general.product-type-' + this.type)
    }

    getCategoryText() {
        let _categoryText = '';

        if(this.category != null) {
            for(let _category of this.category.split(',')) {
                if(_categoryText != '') _categoryText += ', '

                let _icon = this.getCategoryIcon(_category, 'far');
                if(_icon != null) _categoryText += '<i class="' + _icon + ' opacity-75 me-2"></i>'

                _categoryText += this.getCategoryTranslation(_category);
            }
        }

        let _subcategoryText = '';

        if(this.subcategory != null) {
            for(let _subcategory of this.subcategory.split(',')) {
                if(_subcategoryText != '') _subcategoryText += ', '
                _subcategoryText += this.getSubcategoryTranslation(_subcategory);
            }
        }

        let _retVal = _categoryText;

        if(_subcategoryText != '') {
            if(_retVal != '') _retVal += ' - '
            _retVal += _subcategoryText;
        }

        if(_retVal != '') return _retVal;
    }

    getCategoryIcon(category:string, variant?:string) {
        if(variant == null) variant = 'fa'

        if(category == '*') return variant + ' fa-asterisk';
        else {
            let _translType = this.type.charAt(0).toUpperCase() + this.type.substring(1)

            if(this.type == 'eatery' || this.type == 'host') _translType += '.type'
            else _translType += '.category'

            return this.localizationService.icon('OPTIONMAPS.' + _translType + '.' + this.category, variant)
        }
    }

    getCategoryTranslation(category:string) {
        if(category == '*') return this.localizationService.translate('experience-finder.ef-filter.categories-all');
        else {
            let _translType = this.resource

            if(/:/.test(_translType)) _translType = _translType.split(':')[1];

            _translType = _translType.charAt(0).toUpperCase() + _translType.substring(1)
            if(_translType == 'Poi') _translType = 'EFPOI'

            if(this.resource == 'eatery' || this.resource == 'host') _translType += '.type'
            else _translType += '.category'

            return this.localizationService.translate('OPTIONMAPS.' + _translType + '.' + category)
        }
    }

    getSubcategoryTranslation(subcategory:string) {
        if(subcategory == '*') return this.localizationService.translate('experience-finder.ef-filter.subcategories-all');
        else {
            let _translType = this.resource

            if(/:/.test(_translType)) _translType = _translType.split(':')[1];

            _translType = _translType.charAt(0).toUpperCase() + _translType.substring(1)
            if(_translType == 'Poi') _translType = 'EFPOI'

            _translType += '.subcategory'

            return this.localizationService.translate('OPTIONMAPS.' + _translType + '.' + subcategory)
        }
    }

    private _isDateBookable(date:string|{ begin: string, end: string }) {
        if(date != null) {
            if(typeof date == 'object') {
                date = date.end
            }

            return new Date(date).getTime() > Date.now();
        }
    }

    hasBookableDates() {
        if(this.dates != null) {
            for(let _date of this.dates) {
                if(this._isDateBookable(_date)) return true;
            }
        }
    }

    getDateDisplay(withDays?:boolean) {
        let _datesList = this.dates_days;
        if(!withDays) _datesList = this.dates as any;

        if(_datesList == null || _datesList.length == 0) return null;
        else {
            let _retVal = '';

            let _now = new Date();

            for(let _date of _datesList) {
                try {
                    if(_retVal != '') _retVal += ', '

                    if(typeof _date == 'string') {
                        if(this._isDateBookable(_date)) {
                            _retVal += this.localizationService.format.date(_date);
                        }
                    }
                    else {
                        // TODO: sistemare questo approccio un po' bucolico
                        if(this._isDateBookable(_date)) {
                            if(!withDays || (_date.days != null && _date.days != '0000000')) {
                                let _currentYear = _now.getFullYear().toString();
                                
                                if(_date.begin == _currentYear + '-01-01' && _date.end == _currentYear + '-12-31') {
                                    _retVal += this.localizationService.translate('experience-finder.general.all-year', { year: _currentYear });
                                }
                                else {
                                    _retVal += this.localizationService.format.date(_date.begin, false, true) + ' - ' + this.localizationService.format.date(_date.end, false, true);
                                }

                                if(withDays) {
                                    _retVal += '<div class="d-flex">';

                                    if(_date.days == '1111111') {
                                        _retVal += this.localizationService.translate('experience-finder.general.every-day')
                                    }
                                    else {
                                        for(let i = 0; i < 7; i++) {
                                            let _index = (this.localizationService.data.weekStart + i) % 7;
                                            _retVal += '<div class="PGTimetableFormat-Day ' + (_date.days.charAt(_index) == '1' ? 'active' : '') + '">' + this.localizationService.data.dayNames[_index].charAt(0) + '</div>'
                                        }
                                    }
    
                                    _retVal += '</div>';
                                }
                            }
                        }
                    }
                }
                catch(ex) {}
            }

            if(_retVal != '') return _retVal
        }
    }

    getTimeDisplay() {
        if(this.dates == null || this.dates.length == 0) return null;
        else {
            let _retVal = '';

            for(let _date of this.dates) {
                if(typeof _date == 'string' && /T/.test(_date)) {
                    try {
                        if(_retVal != '') _retVal += ', '
                        _retVal += this.localizationService.format.time(_date);
                    }
                    catch(ex) {}
                }
            }

            if(_retVal != '') return _retVal
        }
    }

    getAvailabilityType() {
        if(this.type == 'host' || this.type == 'poi') {
            return 'timetable-openings'
        }
        else {
            if(this.type == 'event') {
                return 'timetable-dates'
            }
            else {
                if(this.availability.list[0] != null && this.availability.list[0].slotDuration != null && !/^00:00/.test(this.availability.list[0].slotDuration)) {
                    return 'timetable-slots'
                }
                else {
                    return 'timetable-days'
                }
            }
        }
    }

    hasItinerary() {
        return this.getItineraryId() != null;
    }

    getItineraryId() {
        if(this.type == 'itinerary') return this.id.split('_')[1];
    }

    hasParent() {
        return this.parent != null;
    }

    getParentId() {
        return this.resource + '_' + this.parent;
    }

    canHaveChildren() {
        return this.resource == 'experience~Event'
    }

    isBookable() {
        return this.bookable && this.hasBookableDates();
    }
}

export class EFPOI {
    id: string = null;
    name:string = null;
    
    translations:{
        [lang:string]: any
    } = {}

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    this[i] = fromData[i]
                }
            }
        }
    }   
}

export class EFSupplier {
    id: string = null;
    resource:string = null;
    
    name:string = null;
    logo:string = null;

    email:string = null;
    phone:string = null;
    landline_number:string = null;
    website:string = null;

    error?:boolean = null;

    constructor(fromData?:any, fromResource?:string) {
        if(fromData != null && fromResource != null) {
            this.resource = fromResource;

            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    this[i] = fromData[i]
                }
            }
        }
    }
}

export type EFItineraryContentResource = 'Experience'|'Host'|'Eatery'|'ExperienceSupplier'|'Poi'|'External'

export class EFItineraryContent { 
    resource:EFItineraryContentResource = null;
    element:string = null;

    title?:string = null;
    text?:string = null;
    image?:string = null;
    geolocation?: PGLocation = null;
    day?:number = null;
    file_gpx?:string = null;

    // NB: queste property non inizializzate a null non vengono salvate

    loading?:boolean;
    error?:boolean;
}

export type EFItineraryMeanOfTransport = 'walking'|'bicycling'|'driving'|'motorcycle'|'public_transport'

export class EFItineraryData {
    id:string = null;
    user_id:string = null;
    name:string = null;
    description:string = null;
    languages:Array<string> = null;
    videos:Array<string> = null;
    link_video:string = null;
    cover:string = null;
    category:Array<string> = null;
    tags:string = null;

    collection:Array<EFItineraryContent> = [];
    services:Array<EFItineraryContent> = [];

    type:string = null;
    mean_of_transport:Array<EFItineraryMeanOfTransport> = null;
    road_type:string = null;
    surface_type:string = null;
    road_signs:boolean = null;
    bike_type:string = null;
    difficulty:string = null;
    duration:number = null;

    travel_distance:number = null;
    travel_time:number = null;

    website:string = null;
    file_gpx:string = null;

    physical_accessibility:string = null;
    cognitive_accessibility:string = null;
    blindness_accessibility:string = null;
    deafness_accessibility:string = null;

    geolocation: PGLocation = null;

    region:string = null;
    province:string = null;
    city:string = null;
    address:string = null;
    zipcode:string = null;

    realm_id:string = null;
}

export class EFItinerary extends EFItineraryData {
    isFullyLoaded = false;
    isFullyLoadedChange = new Subject<boolean>()

    constructor(fromData?:any, private efDataService?:EFDataService, private useDataPath?:boolean) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined' && fromData[i] != null) {
                    this[i] = fromData[i]
                }
            }

            for(let i of ['collection','services']) {
                if(typeof this[i] == 'string') {
                    this[i] = JSON.parse(this[i])
                }
            }

            if(this.mean_of_transport != null) {
                let _val:any = this.mean_of_transport;

                try {
                    this.mean_of_transport = JSON.parse(_val);
                }
                catch(ex) {
                    if(_val != '') {
                        this.mean_of_transport = [_val]
                    }
                }
            }

            this._checkCollectionDataContent();
        }
    }

    toData() {
        let _retVal = new EFItineraryData()

        for(let i in _retVal) {
            if(i == 'collection' || i == 'services') {
                _retVal[i] = []

                if(this[i] != null) {
                    for(let _item of this[i]) {
                        let _save = new EFItineraryContent();
    
                        for(let j in _save) {
                            _save[j] = _item[j]
                        }
    
                        _retVal[i].push(_save)
                    }
                }
            }
            else {
                _retVal[i] = this[i]
            }
        }

        return _retVal;
    }

    private _checkCollectionDataContent() {
        if(this.efDataService != null) {
            let _reqNum = 0;

            let _itemList:Array<EFItineraryContent> = [];

            for(let _item of this.collection) {
                _itemList.push(_item)
            }

            for(let _item of this.services) {
                _itemList.push(_item)
            }

            for(let i = 0; i < _itemList.length; i++) {
                let _item = _itemList[i];

                if(_item.resource != 'External' && _item.geolocation == null) {
                    _item.loading = true;
                    _reqNum++;

                    this.efDataService.getResourceItineraryContent(_item.resource, _item.element, this.useDataPath).subscribe((data) => {
                        _item.loading = false;

                        for(let j in data) {
                            _itemList[i][j] = data[j];
                        }

                        _reqNum--;

                        if(_reqNum == 0) {
                            this.isFullyLoaded = true;
                            this.isFullyLoadedChange.next(this.isFullyLoaded)
                        }
                    })
                }
            }

            this.isFullyLoaded = _reqNum == 0;
            this.isFullyLoadedChange.next(this.isFullyLoaded)
        }
    }

    addItemToCollection(item:EFItineraryContent) {
        this.collection.push(item);

        this._checkCollectionDataContent();
    }

    addItemToServices(item:EFItineraryContent) {
        this.services.push(item);

        this._checkCollectionDataContent();
    }

    getDirectionsParameters() {
        if(this.collection.length > 1) {
            let _origin:EFItineraryContent = null;
            let _destination:EFItineraryContent = null;

            let _waypoints:Array<google.maps.DirectionsWaypoint> = null;

            for(let _item of this.collection) {
                if(_item.geolocation != null) {
                    if(_origin == null) {
                        _origin = _item;
                    }

                    if(_destination != null) {
                        if(_waypoints == null) _waypoints = [];

                        _waypoints.push({
                            location: new google.maps.LatLng(_destination.geolocation.coordinates[0], _destination.geolocation.coordinates[1]),
                            stopover: true
                        })
                    }

                    _destination = _item;
                }
            }

            if(_origin != null && _destination != _origin) {
                let _travelMode:google.maps.TravelMode = google.maps.TravelMode.DRIVING;

                if(this.mean_of_transport != null) {
                    if(this.mean_of_transport[0] == 'walking') _travelMode = google.maps.TravelMode.WALKING;
                    else if(this.mean_of_transport[0] == 'bicycling') _travelMode = google.maps.TravelMode.BICYCLING;
                    else if(this.mean_of_transport[0] == 'public_transport') _travelMode = google.maps.TravelMode.TRANSIT;
                }
    
                return {
                    origin: new google.maps.LatLng(_origin.geolocation.coordinates[0], _origin.geolocation.coordinates[1]),
                    destination: new google.maps.LatLng(_destination.geolocation.coordinates[0], _destination.geolocation.coordinates[1]),
                    waypoints: _waypoints,
                    travelMode: _travelMode
                }
            }
        }
    }
}

export class EFFilterData {
    type:EFProductType = null;
    allCategories = true;
    allSubcategories = true;
    allStars = true;
    allCosts = true;
    category:{
        [type:string]: {
            [category:string]: boolean
        }
    } = {};
    subcategory:{
        [type:string]: {
            [category:string]: {
                [subcategory:string]: boolean
            }
        }
    } = {};
    tags:Array<string> = null;
    groups:Array<string> = null;
    interval:{ from: string, to: string } = null;
    stars:{
        [type:string]: {
            [stars:number]: boolean
        }
    } = {};
    cost:{
        [type:string]: Array<{
            from: number,
            to: number,
            selected: boolean
        }>
    } = {};
    language:{
        [type:string]: {
            [language:string]: boolean
        }
    } = {};
    realm:string = null;
    ids:Array<string> = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    this[i] = fromData[i]
                }
            }
        }
    }
}