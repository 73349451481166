import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PGAvailabilitySelection, PGAvailabilitySelectionLapse, PGBookingData, PGBookingsCountData, PGBrokerData, PGVoucherData, PGProductData, PGTicketsSelection, PGSupplierData, PGUserContact, PGSurveyAnswers, PgBookingsCountDayData, PGPaymentMethod } from '../models/booking.model';
import { DataService, GetResourceDataOptions, DataFilter } from './data.service';
import { PGUtilities } from '../pg-utilities';

export type BookingServiceListType = 'customer'|'broker'|'supplier/experience'|'supplier/eatery'|'supplier/host'

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    constructor(private dataService:DataService) { 
    }
    
    listBookings(requestAs?:BookingServiceListType, options?:GetResourceDataOptions) {
        return new Observable<Array<PGBookingData>>((observer) => {

            let _queryURL = requestAs == null ? '/data/booking' : '/booking/' + requestAs

            if(options == null) {
                options = {}
            }

            if(options.filter == null) {
                options.filter = [];
            }

            options.filter.push({ field: 'status', operator: '!=', value: ['draft'] })

            if(options.order == null) {
                options.order = [];
            }

            options.order.push({ field: 'date', direction: 'asc' })
            options.order.push({ field: 'start', direction: 'asc' })
            options.order.push({ field: 'experience_type', direction: 'asc' })
            options.order.push({ field: 'experience_id', direction: 'asc' })

            options.with = ['User']

            let _searchString = this.dataService.getDataOptionsSearchString(options);

            if(_searchString != '') _queryURL += '?' + _searchString;

            this.dataService.getRequest(_queryURL).subscribe(data => {
                observer.next(data as Array<PGBookingData>);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getBooking(id:string, useDataPath?:boolean) {
        return new Observable<PGBookingData>((observer) => {
            this.dataService.getRequest('/' + (useDataPath ? 'data' : 'public') + '/booking/' + id + '?with=related_user').subscribe(data => {
                observer.next(data as PGBookingData);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getBookingsCount(resource:string, product:string, from:Date, to:Date) {
        return new Observable<PGBookingsCountData>((observer) => {
            let _retVal:PGBookingsCountData = {};
            let _reqCount = 0;

            let _toExcl = new Date(to.getTime() - 1)

            let _reqDate = new Date(from.getFullYear(), from.getMonth(), 1)
            let _reqTo = new Date(_toExcl.getFullYear(), _toExcl.getMonth() + 1, 0)

            while(_reqDate < _reqTo) {
                _reqCount++;
                this.dataService.getRequest('/public/count/booking/' + resource + '/' + product + '/' + _reqDate.getFullYear() + '/' + (_reqDate.getMonth() + 1)).subscribe(data => {
                
                    for(let _cData of data as Array<any>) {
                        let _cYear = parseInt(_cData.date.split('-')[0]);
                        let _cMonth = parseInt(_cData.date.split('-')[1]);
                        let _cDay = parseInt(_cData.date.split('-')[2]);
                        let _cTime = _cData.start == null ? '00:00' :_cData.start.match(/\d\d:\d\d/)[0]
    
                        let _cDate = new Date(_cYear, _cMonth - 1, _cDay, parseInt(_cTime.split(':')[0]), parseInt(_cTime.split(':')[1]))
  
                        if(_cDate.getTime() >= from.getTime() && _cDate.getTime() < to.getTime()) {
                            if(_retVal[_cYear] == null) {
                                _retVal[_cYear] = {};
                            }
        
                            if(_retVal[_cYear][_cMonth] == null) {
                                _retVal[_cYear][_cMonth] = {};
                            }
        
                            if(_retVal[_cYear][_cMonth][_cDay] == null) {
                                _retVal[_cYear][_cMonth][_cDay] = {};
                            }
        
                            _retVal[_cYear][_cMonth][_cDay][_cTime] = _cData.total;
                        }
                    }

                    _reqCount--
                    if(_reqCount == 0) {
                        observer.next(_retVal);
                        observer.unsubscribe();
                    }
                }, (error) => {
                    _reqCount--;
                    if(_reqCount == 0) {
                        observer.next(_retVal);
                        observer.unsubscribe();
                    }
                })    

                _reqDate.setMonth(_reqDate.getMonth() + 1)
            }            
        })
    }

    getBookingsCountDay(resource:string, product:string, year:number, month:number, day:number) {
        return new Observable<PgBookingsCountDayData>((observer) => {
            let _retVal:PgBookingsCountDayData = {};

            this.dataService.getRequest('/public/count/booking/' + resource + '/' + product + '/' + year + '/' + month).subscribe(data => {                
                for(let _cData of data as Array<any>) {
                    let _cYear = parseInt(_cData.date.split('-')[0]);
                    let _cMonth = parseInt(_cData.date.split('-')[1]);
                    let _cDay = parseInt(_cData.date.split('-')[2]);

                    if(_cYear == year && _cMonth == month && _cDay == day) {
                        _retVal[_cData.start.match(/\d\d:\d\d/)[0]] = _cData.total;
                    }
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, (error) => {
                observer.next(_retVal);
                observer.unsubscribe();
            })
        })
    }

    listDrafts(productData?:PGProductData, user?:string, limit?:number) {
        return new Observable<any>((observer) => {
            let _filter:Array<DataFilter> = [];

            if(productData != null) {
                _filter.push({ field: 'status', operator: '==', value: [ 'draft' ] })
                _filter.push({ field: 'experience_type', operator: '==', value: [ productData.type ] })
                _filter.push({ field: 'experience_id', operator: '==', value: [ productData.id ] })
            }

            if(user != null) {
                _filter.push({ field: 'user_id', operator: '==', value: [ user ] })
            }

            this.dataService.getResourceData('Booking', { filter: _filter, limit: limit }).subscribe((data) => {
                observer.next(data);
                observer.unsubscribe();
            })
        })
    }

    createDraft(productData:PGProductData, selectedLanguage:string, selectedAvailability:PGAvailabilitySelection|PGAvailabilitySelectionLapse, selectedTickets:PGTicketsSelection, bookingData:any, surveyAnswers:PGSurveyAnswers, userContact:PGUserContact, paymentMethod:PGPaymentMethod, additionalInformations:string, voucherCode?:string, brokerCode?:string) {
        return new Observable<any>((observer) => {
            let _cBooking = new PGBookingData(null, productData, selectedLanguage, selectedAvailability, selectedTickets, bookingData, surveyAnswers, userContact, paymentMethod, additionalInformations, voucherCode, brokerCode);
            _cBooking.user_email = PGUtilities.cleanRecipient( _cBooking.user_email, 'email')
            _cBooking.user_phone = PGUtilities.cleanRecipient( _cBooking.user_phone, 'phone')

            this.dataService.postRequest('/booking/draft', _cBooking, {
                headers: { 'X-Interceptor-Silent': '409' }
            }).subscribe((data) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    updateDraft(id:string, selectedLanguage:string, surveyAnswers:PGSurveyAnswers, userContact:PGUserContact, paymentMethod:PGPaymentMethod, additionalInformations:string, voucherCode?:string, brokerCode?:string) {
        return new Observable<any>((observer) => {
            let _cBooking = new PGBookingData(id, null, selectedLanguage, null, null, null, surveyAnswers, userContact, paymentMethod, additionalInformations, voucherCode, brokerCode);
            _cBooking.user_email = PGUtilities.cleanRecipient( _cBooking.user_email, 'email')
            _cBooking.user_phone = PGUtilities.cleanRecipient( _cBooking.user_phone, 'phone')

            delete _cBooking.experience_type;
            delete _cBooking.experience_id;
            delete _cBooking.experience_data;

            delete _cBooking.availability;
            delete _cBooking.booking_data;
            delete _cBooking.tickets;

            delete _cBooking.pid;
            delete _cBooking.status;
            delete _cBooking.price;

            this.dataService.putRequest('/booking/draft/' + id,  _cBooking, {
                headers: { 'X-Interceptor-Silent': '404' }
            }).subscribe((data) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    deleteDraft(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.deleteRequest('/booking/draft/' + id, {
                headers: { 'X-Interceptor-Silent': '404' }
            }).subscribe((data) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    confirmDraft(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/booking/save/' + id, {}).subscribe((data) => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    listProducts(type:'experience'|'host'|'eatery', useData?:boolean) {
        return new Observable<Array<PGProductData>>((observer) => {
            this.dataService.getRequest('/' + (useData ? 'data' : 'public') + '/' + type).subscribe(data => {
                let _retVal:Array<PGProductData> = [];
                for(let _cData of data) {
                    _retVal.push(new PGProductData(_cData, type))
                }
                observer.unsubscribe();
            }, (error) => {
                observer.next([]);
                observer.unsubscribe();
            })
        })
    }

    getProduct(id:string, type:'experience'|'host'|'eatery', useData?:boolean) {
        return new Observable<PGProductData>((observer) => {
            this.dataService.getRequest('/' + (useData ? 'data' : 'public') + '/' + type + '/' + id).subscribe((data:any) => {
                observer.next(new PGProductData(data, type));
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    getProductSupplier(product:PGProductData, useData?:boolean) {
        return new Observable<PGSupplierData>((observer) => {
            let _cSupplierSplit = product.supplier.split('_');

            this.dataService.getRequest('/' + (useData ? 'data' : 'public') + '/' + this.dataService.normalizeResourceName(_cSupplierSplit[0]) + '/' + _cSupplierSplit[1]).subscribe((data:any) => {
                observer.next(data as PGSupplierData);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    payOnline(booking:PGBookingData) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/pay/booking', { 
                bookings: [ booking.id ]
            }).subscribe(data => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    checkBrokerCode(code:string) {
        return new Observable<PGBrokerData>((observer) => {
            this.dataService.getRequest('/public/broker/' + code).subscribe((data) => {
                observer.next(data as PGBrokerData);
                observer.unsubscribe();
            }, (error) => {
                observer.error('invalid');
                observer.unsubscribe();
            })
        })
    }

    checkVoucherCode(code:string) {
        return new Observable<PGVoucherData>((observer) => {
            this.dataService.getRequest('/public/voucher/' + code).subscribe((data) => {
                let _cData = new PGVoucherData(data);

                let _now = new Date();

                if(_cData.start != null && _cData.start > _now) {
                    observer.error('before-start');
                    observer.unsubscribe();
                }
                else if(_cData.end != null && _cData.end < _now) {
                    observer.error('after-end');
                    observer.unsubscribe();
                }
                else {
                    observer.next(_cData);
                    observer.unsubscribe();
                }
            }, (error) => {
                observer.error(null);
                observer.unsubscribe();
            })
        })
    }

    calculateSelectedTicketsTotal(tickets:PGTicketsSelection) {
        let _cTotal = 0;
        _cTotal += tickets.fullQuantity * tickets.fullPrice;

        for(let _cOther of tickets.otherTickets) {
            _cTotal += _cOther.quantity * _cOther.price;
        }

        return _cTotal;
    }

    calculateBrokerQuota(product:PGProductData, tickets:PGTicketsSelection, discount?:number) {
        let _cPrice = this.calculateSelectedTicketsTotal(tickets);

        if(discount != null) {
            _cPrice *= (1 - discount / 100)
        }

        let _brokerDiscount = product.broker_discount;
        if(_brokerDiscount == null) _brokerDiscount = 0;

        return Math.round(_cPrice * (_brokerDiscount / 100) * 100) / 100;
    }

    cancelBooking(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/booking/cancel/' + id, null).subscribe(data => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    confirmBooking(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/booking/confirm/' + id, null).subscribe(data => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    userCancelBooking(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/booking/userCancel/' + id, null).subscribe(data => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }

    payBroker(id:string) {
        return new Observable<any>((observer) => {
            this.dataService.postRequest('/booking/brokerConfirm/' + id, null).subscribe(data => {
                observer.next(data);
                observer.unsubscribe();
            }, (error) => {
                observer.next(null);
                observer.unsubscribe();
            })
        })
    }
}
