import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgChatComponent } from './pg-chat/pg-chat.component';
import { PgCustomerCareComponent } from './pg-customer-care/pg-customer-care.component';
import { PgCallParticipantComponent } from './pg-call-participant/pg-call-participant.component';
import { PgCallComponent } from './pg-call/pg-call.component';

@NgModule({
    declarations: [
        PgChatComponent,
        PgCustomerCareComponent,
        PgCallParticipantComponent,
        PgCallComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiControlsModule,
        PgUiElementsModule,
    ],
    exports: [
        PgChatComponent,
        PgCustomerCareComponent,
        PgCallParticipantComponent,
        PgCallComponent,
    ],
    providers:[]
})
export class PgChatModule { }
