import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { EnvironmentService } from '../../services/environment.service';
import { PGUtilities } from '../../pg-utilities';

@Component({
    selector: 'app-pg-recipient',
    templateUrl: './pg-recipient.component.html',
    styleUrls: ['./pg-recipient.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgRecipientComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgRecipientComponent),
        multi: true,
    }]
})
export class PgRecipientComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @Input() id:string;

    @Input() readonly: boolean;
    @Input() required: boolean;

    @Input() compositeValue: boolean;

    @Input() disablePhone: boolean;

    @Output() viaChange = new EventEmitter<'email'|'phone'>();

    via:'email'|'phone' = null;
    value:string = null;

    constructor(private environmentService:EnvironmentService) { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        if(this.environmentService.environment.DisableSMS) this.disablePhone = true;
    }

    private _checkViaByRecipient () {
        let _cVia = null;

        if(this.value != null && this.value != '') {
            if(!this.disablePhone && this.value.length > 3 && PGUtilities.phoneRegExp.test(this.value)) _cVia = 'phone';
            else if(PGUtilities.emailRegExp.test(this.value)) _cVia = 'email';
        }
    
        if(_cVia != this.via) {
            this.via = _cVia;

            if(this.compositeValue) {
                this.onRecipientChange();
            }
            else {
                this.viaChange.emit(this.via);
            }
        }
    }

    onRecipientChange() {
        this._checkViaByRecipient()

        if(this._onTouched) this._onTouched();
        if(this._onChange) { // TODO: perché qua dentro non faccio già una PGUtilities.cleanRecipient?
            if(this.compositeValue && this.value != null) {
                this._onChange((this.via || '') + '/' + (this.value || ''));
            }
            else {
                this._onChange(this.value);
            }
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        if(this.compositeValue && obj != null) {
            this.via = obj.split('/')[0]
            this.value = obj.replace(/^[^\/]*\//, '')
        }
        else {
            this.value = obj;
        }

        this._checkViaByRecipient()
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        if(this.value == null || this.value == '') {
            if(this.required) {
                return {
                    required: {
                        valid: false
                    }
                } 
            }
        }
        else if(this.via == null) {
            return {
                format: {
                    valid: false
                }
            } 
        }
    };
}
