import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-pg-content-view',
  templateUrl: './pg-content-view.component.html',
  styleUrls: ['./pg-content-view.component.scss']
})
export class PgContentViewComponent implements OnInit {

    constructor(private route:ActivatedRoute, private localizationService:LocalizationService) { }

    contentId:string = null;
    contentData:string = null;

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.contentId = params.id
            this.contentData = null;

            let _translated = this.localizationService.translate(this.contentId);
            if(_translated != this.contentId) {
                this.contentData = _translated;
            }
        })
    }
}
