import { Component, Input, OnInit } from '@angular/core';
import { EFDataService, EFProduct } from 'pg-lib';

@Component({
  selector: 'app-ef-showcase-view',
  templateUrl: './ef-showcase-view.component.html',
  styleUrls: ['./ef-showcase-view.component.scss']
})
export class EfShowcaseViewComponent implements OnInit {

    @Input() realmId:string;

    constructor(private dataService:EFDataService) { }

    isLoading = false;

    productsData:Array<EFProduct> = null;

    ngOnInit(): void {
        this.isLoading = true;

        this.dataService.listProducts(null, null, this.realmId).subscribe((data) => {

            this.isLoading = false;

            this.productsData = [];

            for(let i in data) {
                for(let _product of data[i]) {
                    this.productsData.push(_product)
                }
            }
        })
    }

}
