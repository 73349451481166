import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { LocalizationService } from './localization.service';
import { EFFilterData, EFItinerary, EFItineraryContent, EFItineraryContentResource, EFPOI, EFProduct, EFProductType, EFSupplier } from '../models/experience.model';
import { PGUtilities } from '../pg-utilities';
import { TimetableDataListItem } from '../models/timetable.model';

class EFGeoJSON {
    type: "FeatureCollection"
    name: string
    features: Array<{ 
        type: "Feature", 
        properties: any, 
        geometry: { 
            type: "Point", 
            coordinates: [ number, number ] 
        } 
    }>
}

@Injectable({
    providedIn: 'root'
})
export class EFDataService {
    constructor(private http:HttpClient, private environmentService:EnvironmentService, private localizationService:LocalizationService) {
        this.localizationService.addTranslationLink('OPTIONMAPS.Event', 'OPTIONMAPS.Experience')
        this.localizationService.addTranslationLink('OPTIONMAPS.Experience~Event', 'OPTIONMAPS.Experience')
        this.localizationService.addIconLink('OPTIONMAPS.Event', 'OPTIONMAPS.Experience')
        this.localizationService.addIconLink('OPTIONMAPS.Experience~Event', 'OPTIONMAPS.Experience')
    }

    listSuppliers(realm?:string) {
        return new Observable<Array<EFSupplier>>((observer) => {
            let _retVal:Array<EFSupplier> = [];

            let _reqResources = ['host','eatery','experienceSupplier'];
            let _reqNum = 0;

            for(let _resource of _reqResources) {
                _reqNum++
                this._getResourceWithCache(_resource, null, null, realm).subscribe(data => {
                    for(let _cData of data as Array<any>) {
                        _cData.id = _resource.charAt(0).toUpperCase() + _resource.substring(1) + '_' + _cData.id

                        _retVal.push(new EFSupplier(_cData, _resource))
                    }
    
                    _reqNum--
                    if(_reqNum <= 0) {
                        observer.next(_retVal);
                        observer.unsubscribe();
                    }
                }, (error) => {
                    _reqNum--
                    if(_reqNum <= 0) {
                        observer.next(_retVal);
                        observer.unsubscribe();
                    }
                })
            }
        })
    }

    listPOIs(realm?:string) {
        return new Observable<Array<EFPOI>>((observer) => {
            let _retVal:Array<EFPOI> = [];

            this._getResourceWithCache('Poi', null, null, realm).subscribe(data => {
                for(let _cData of data as Array<any>) {
                    _retVal.push(new EFPOI(_cData))
                }

                observer.next(_retVal);
                observer.unsubscribe();
            }, () => {
                observer.next(_retVal);
                observer.unsubscribe();
            })
        })
    }

    private _getDatesFromAvailability(availability:string, withDays?:boolean) {
        if(availability != null) {
            let _availabilityList:Array<TimetableDataListItem> = PGUtilities.tryParseJSON(availability);

            let _dates:Array<string|{ begin: string, end: string, days:string }> = [];

            let _lapseList:Array<{ begin: Date, end: Date, days:string }> = [];

            for(let _availability of _availabilityList) {
                if(_availability.days != null) {
                    let _dayString:string = null;

                    if(withDays) {
                        let _daysList = [false,false,false,false,false,false,false];

                        for(let _hour of _availability.hours) {
                            for(let i = 0; i < 7; i++) {
                                if(_hour.days[i]) _daysList[i] = true;
                            }
                        }

                        _dayString = '';
                        for(let _item of _daysList) {
                            _dayString += _item ? '1' : '0'
                        }
                    }
                    
                    for(let _days of _availability.days) {
                        if(_days.begin != null) {
                            if(_dates == null) _dates = [];
                            let _cYear = _days.year;
                            if(_cYear == null) _cYear = new Date().getFullYear();

                            let _beginDate = new Date(_cYear, _days.begin.month - 1, _days.begin.day);

                            if(_days.end == null) {
                                if(_days.year == null) { // se è un evento ciclico annuale, imposto l'anno al prossimo inizio futuro
                                    if(_beginDate.getTime() < Date.now()) {
                                        _beginDate.setFullYear(_beginDate.getFullYear() + 1)
                                    }
                                }

                                _lapseList.push({ begin: _beginDate, end: _beginDate, days: _dayString })
                            }
                            else {
                                let _endDate = new Date(_beginDate.getFullYear(), _days.end.month - 1, _days.end.day);
                                if(_endDate.getTime() < _beginDate.getTime()) _endDate.setFullYear(_endDate.getFullYear() + 1) // se la data di fine è precedente a quella di inizio la sposto all'anno dopo

                                if(_days.year == null) { // se è un evento ciclico annuale, imposto l'anno alla prossima fine futura
                                    if(_endDate.getTime() < Date.now()) {
                                        _beginDate.setFullYear(_beginDate.getFullYear() + 1)
                                        _endDate.setFullYear(_endDate.getFullYear() + 1)
                                    }
                                }

                                // setto la fine al giorno +1, in modo che si riesca a fare il merge per inizio = fine
                                _endDate.setDate(_endDate.getDate() + 1)

                                if(_endDate.getTime() > Date.now()) {
                                    _lapseList.push({ begin: _beginDate, end: _endDate, days: _dayString })
                                }
                            }
                        }
                    }
                }
            }

            let _mergedList:Array<{ begin: Date, end: Date, days:string }> = []

            for(let _item of _lapseList) {
                let _wasMerged = false;

                for(let _mergedItem of _mergedList) {
                    if(_item.days == _mergedItem.days && _item.begin.getTime() <= _mergedItem.end.getTime() && _item.end.getTime() >= _mergedItem.begin.getTime()) {

                        if(_item.begin.getTime() < _mergedItem.begin.getTime()) {
                            _mergedItem.begin = _item.begin;
                        }

                        if(_item.end.getTime() > _mergedItem.end.getTime()) {
                            _mergedItem.end = _item.end;
                        }

                        _wasMerged = true;
                        break;
                    }
                }

                if(!_wasMerged) _mergedList.push(_item)
            }

            for(let _item of _mergedList) {
                _item.end.setDate(_item.end.getDate() - 1)  // tolgo il giorno che avevo aggiunto prima

                let _beginString = this._getDateString(_item.begin.getFullYear(), _item.begin.getMonth() + 1, _item.begin.getDate())
                let _endString = this._getDateString(_item.end.getFullYear(), _item.end.getMonth() + 1, _item.end.getDate())

                if(_endString == _beginString) {
                    _dates.push(_beginString)
                }
                else {
                    _dates.push({ begin: _beginString, end: _endString, days: _item.days })
                }
            }

            return _dates;
        }
    }

    private _getDateString(year:number, month:number, day:number) {
        let _retVal = year + '-'
        if(month  < 10) _retVal += '0';
        _retVal += month + '-'
        if(day  < 10) _retVal += '0';
        _retVal += day

        return _retVal;
    }

    private _mapProductData: { [resource:string]: (data:any) => any } = {
        experience: (data) => {

            if(typeof data.payment == 'string') {
                data.payment = [data.payment]
            }

            if(data.availability != null) {
                data.dates = this._getDatesFromAvailability(data.availability)
                data.dates_days = this._getDatesFromAvailability(data.availability, true)
            }

            return data;
        },
        'experience~Event': (data) => {
            return this._mapProductData.experience(data);
        },
        host: (data) => {
            data.title = data.name;
            data.category = data.type;

            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    _cTranslation.title = _cTranslation.name
                }
            }

            return data;
        },
        eatery: (data) => {
            data.title = data.name;
            data.category = data.type;

            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    _cTranslation.title = _cTranslation.name
                }
            }

            return data;
        },
        experienceSupplier: (data) => {
            //data.title = data.name;
            //TODO: va ripristinato il mapping su name
            data.title = data.company_name;

            this.localizationService.addTranslationAlias('OPTIONMAPS.EFPOI.category.' + data.category, 'OPTIONMAPS.ExperienceSupplier.category.' + data.category)
            this.localizationService.addIconAlias('OPTIONMAPS.EFPOI.category.' + data.category, 'OPTIONMAPS.ExperienceSupplier.category.' + data.category)

            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    _cTranslation.title = _cTranslation.name
                }
            }

            return data;
        },
        poi: (data) => {
            data.title = data.name;

            this.localizationService.addTranslationAlias('OPTIONMAPS.EFPOI.category.' + data.category, 'OPTIONMAPS.Poi.category.' + data.category)
            this.localizationService.addIconAlias('OPTIONMAPS.EFPOI.category.' + data.category, 'OPTIONMAPS.Poi.category.' + data.category)
            
            this.localizationService.addTranslationAlias('OPTIONMAPS.EFPOI.subcategory.' + data.subcategory, 'OPTIONMAPS.Poi.subcategory.' + data.subcategory)

            if(data.availability != null) {
                data.dates = this._getDatesFromAvailability(data.availability)
                data.dates_days = this._getDatesFromAvailability(data.availability, true)
            }

            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    _cTranslation.title = _cTranslation.name
                }
            }

            return data;
        },
        itinerary: (data) => {
            // TODO: sarà da sostituire in qualche modo?
            delete data.services

            data.title = data.name;

            if(data.duration != null && data.duration != '') {
                data.duration = data.duration * 60 * 60 * 1000
            }
            
            if(data.travel_time != null && data.travel_time != '') {
                data.travel_time = data.travel_time * 1000
            }

            if(data.travel_distance != null && data.travel_distance != '') {
                data.travel_distance = data.travel_distance
            }

            data.travel_stops = data.collection?.length;
             
            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    _cTranslation.title = _cTranslation.name
                }
            }
            
            return data;
        },
        article: (data) => {
            data.description = data.text;

            return data;
        },
        municipium: (data) => {
            data.geolocation = (data.latitude && data.longitude) ? {
                type: 'Point',
                coordinates: [
                    parseFloat(data.latitude),
                    parseFloat(data.longitude),
                ]
            } : null;

            if(data.name != null) data.title = data.name;
            if(data.content != null) data.description = data.content;

            if(data.mail != null) data.email = data.mail;
            if(data.web_site != null) data.website = data.web_site;
            
            data.languages = ['it_IT'];

            data.translations = [{
                language: 'it_IT',
                title: data.title,
                description: data.description
            }]

            let _cImageList = [];

            if(data.images != null) {
                for(let _cImage of data.images) {
                    if(_cImage.image.url != null) {
                        if(data.base_api != null) {
                            _cImageList.push(data.base_api.replace(/\/api\/v2\/$/, '') + _cImage.image.url);
                        }
                        else if(_cImage.image.base_url != null) {
                            _cImageList.push(_cImage.image.base_url + _cImage.image.url);
                        }
                    }
                }
            }
            else if(data.image != null) {
                if(data.image.url != null) {
                    if(data.base_api != null) {
                        _cImageList.push(data.base_api.replace(/\/api\/v2\/$/, '') + data.image.url);
                    }
                    else if(data.image.base_url != null) {
                        _cImageList.push(data.image.base_url + data.image.url);
                    }
                }
            }
            
            if(_cImageList.length > 0) {
                data.images = JSON.stringify(_cImageList);
            }
            else data.images = null;

            return data;
        },
        'municipium:poi': (data) => {
            data = this._mapProductData.municipium(data);

            if(data.geolocation != null && data.point_of_interest_categories != null) {
                for(let _cCategory of data.point_of_interest_categories) {
                    if(data.category == null) data.category = '';

                    if(data.category != '') data.category += ','
                    let _cleanName = _cCategory.name.replace(/^\s*/, '').replace(/\s*$/, '')
                    _cleanName = _cleanName.charAt(0).toUpperCase() + _cleanName.substring(1)
                    let _cleanId = _cCategory.name.replace(/^\s*/, '').replace(/\s*$/, '').toLowerCase().replace(/ /g,'_').replace(/[^\w]+/g,'')
                    data.category += _cleanId;

                    this.localizationService.addTranslation('*', 'OPTIONMAPS.EFPOI.category.' + _cleanId, _cleanName)
                }
            }

            return data;
        },
        'municipium:event': (data) => {
            data = this._mapProductData.municipium(data);

            return data;
        },
        'musement:experience': (data) => {
            if(data.category != null && typeof data.category == 'object') {
                this.localizationService.addTranslation('*', 'OPTIONMAPS.Experience.category.' + data.category.code, data.category.name)
                if(data.category.translations != null) {
                    for(let _translation of data.category.translations) {
                        this.localizationService.addTranslation(_translation.language, 'OPTIONMAPS.Experience.category.' + data.category.code, _translation.name)
                    }
                }
                data.category = data.category.code;
            }
            
            data = this._mapProductData.experience(data);

            return data;
        }
    }

    private _checkMapProductData(data:any, resource:string) {
        if(this._mapProductData[resource] != null) {
            return this._mapProductData[resource](data);
        }
        else return data;
    }

    private _mapProductType: { [resource:string]: EFProductType } = {
        'experience~Event': 'event',
        'experienceSupplier': 'poi',
        'musement:experience': 'experience',
        'municipium:poi': 'poi',
        'municipium:event': 'event'
    }

    private _checkMapProductType(resource:string):EFProductType {
        resource = this.normalizeResourceName(resource)

        if(this._mapProductType[resource] != null) {
            return this._mapProductType[resource];
        }
        else return resource as any;
    }

    getProductTypeFromResource(resource:string):EFProductType {
        return this._checkMapProductType(resource)
    }

    private _resourcesCache:{ [resource:string] : { [groups:string] : Array<any> } } = {}

    private _resourceFilters:{ 
        [resource:string]: {
            [filter:string]: Array<any>
        }
    } = {
        'experience': {
            'Event': [{
                field: 'type', operator: '==', value: ['event']
            }],
            '': [{
                field: 'type', operator: '!=', value: ['event']
            }]
        }
    }

    private _getResourceWithCache(resource:string, groups?:Array<string>, parent?:string, realm?:string) {
        return new Observable<Array<any>>((observer) => {
            resource = this.normalizeResourceName(resource);

            let _realm = realm || this.environmentService.publicRealm?.id;

            let _filterString = '';

            if(_realm != null) {
                _filterString += _realm;
            }

            if(groups != null) {
                let _groups = JSON.parse(JSON.stringify(groups))
                _groups.sort();
                _filterString += JSON.stringify(_groups);
            }
            else {
                _filterString += '[]'
            }
            
            if(parent != null) {
                _filterString += parent;
            }

            if(this._resourcesCache[resource] != null && this._resourcesCache[resource][_filterString] != null) {
                setTimeout(() => {
                    observer.next(JSON.parse(JSON.stringify(this._resourcesCache[resource][_filterString])))
                    observer.unsubscribe();
                }, 100)
            }
            else {
                let _resourceId = resource.split('~')[0];
                let _filterId = resource.split('~')[1];
                if(_filterId == null) _filterId = '';
                let _filterList = []

                if(this._resourceFilters[_resourceId] != null && this._resourceFilters[_resourceId][_filterId] != null) {
                    _filterList = JSON.parse(JSON.stringify(this._resourceFilters[_resourceId][_filterId]))
                }

                if(groups != null) {
                    _filterList.push({
                        field: 'group_id', operator: 'in', value: groups
                    })
                }
                
                if(parent != null) {
                    _filterList.push({
                        field: 'parent', operator: '==', value: [parent.split('_')[1]]
                    })
                }

                let _filterQuery = '';
                if(_filterList.length > 0) {
                    _filterQuery = '&filter=' + encodeURIComponent(JSON.stringify(_filterList))
                }

                if(_realm != null) {
                    _filterQuery += '&realm=' + _realm;
                }

                this.http.get(this.environmentService.environment.APIUrl + '/public/' + _resourceId + '?limit=5000' + _filterQuery).subscribe((data) => {
                    if(this._resourcesCache[resource] == null) this._resourcesCache[resource] = {};
                    this._resourcesCache[resource][_filterString] = data as Array<any>;

                    observer.next(JSON.parse(JSON.stringify(this._resourcesCache[resource][_filterString])))
                    observer.unsubscribe();
                })
            }
        })
    }

    normalizeResourceName(resource:string) {
        return resource.charAt(0).toLowerCase() + resource.substring(1);
    }

    private _getResourceProductAnalytics(resource:string, product:any) {
        let _analyticsResource = resource.replace(/~.*$/, '');
        let _analyticsType = null;
        if(_analyticsResource == 'experience') _analyticsType = product.type;

        return { id: product.id, resource: _analyticsResource, type: _analyticsType };
    }

    listResourceProducts(resource:string, groups?:Array<string>, parent?:string, realm?:string) {
        let _type = this._checkMapProductType(resource);

        return this._getResourceWithCache(resource, groups, parent, realm).pipe(map((data) => {
            let _products:Array<EFProduct> = [];

            for(let _item of data as Array<any>) {
                if(groups == null || groups.length == 0 || groups.indexOf(_item.group_id) != -1) {
                    _products.push(new EFProduct(this.localizationService, _type, this._checkMapProductData(_item, resource), resource, this._getResourceProductAnalytics(resource, _item)))
                }
            }

            return _products;
        }))
    }

    getResourceProduct(resource:string, element:string, useDataPath?:boolean) {
        return new Observable<EFProduct>((observer) => {
            resource = this.normalizeResourceName(resource);

            let _resourceId = resource.split('~')[0];

            this.http.get(this.environmentService.environment.APIUrl + '/' + (useDataPath ? 'data' : 'public') + '/' + _resourceId + '/' + element).subscribe((data) => {
                observer.next(new EFProduct(this.localizationService, this._checkMapProductType(resource), this._checkMapProductData(data, resource), resource, this._getResourceProductAnalytics(resource, data)))
                observer.unsubscribe();
            }, () => {
                observer.next(new EFProduct(this.localizationService, this._checkMapProductType(resource), this._checkMapProductData({ error: true }, resource), resource))
                observer.unsubscribe();
            })
        })
    }

    mapResourceItineraryContent(resource:EFItineraryContentResource, data:any):EFItineraryContent {
        let _retVal = new EFItineraryContent()

        _retVal.resource = resource
        _retVal.element = data.id

        if(data.error) {
            _retVal.error = true;
        }
        else {
            let _product = this._checkMapProductData(data, this.normalizeResourceName(resource));

            _retVal.geolocation = _product.geolocation

            for(let _prop of [
                { from: 'title', to: 'title' }, 
                { from: 'description', to: 'text' }
            ]) {

                let _translations:any = { en_EN: _product[_prop.from] }

                if(_product.translations != null) {
                    for(let _translation of _product.translations) {
                        if(_translation[_prop.from] != null) {
                            _translations[_translation.language] = _translation[_prop.from]
                        }
                    }
                }

                _retVal[_prop.to] = JSON.stringify(_translations)
            }

            if(_product.cover != null && _product.cover != '') _retVal.image = _product.cover;
            else if(_product.images != null && _product.images != '') {
                let _imageList = PGUtilities.tryParseJSON(_product.images)
                if(_imageList != null && _imageList[0] != null) {
                    _retVal.image = _imageList[0]
                }
            }
        }

        return _retVal;
    }

    getResourceItineraryContent(resource:EFItineraryContentResource, element:string, useDataPath?:boolean) {
        return new Observable<EFItineraryContent>((observer) => {
            let _resourceId = this.normalizeResourceName(resource);

            this.http.get(this.environmentService.environment.APIUrl + '/' + (useDataPath ? 'data' : 'public') + '/' + _resourceId + '/' + element).subscribe((data) => {
                observer.next(this.mapResourceItineraryContent(resource, data))
                observer.unsubscribe();
            }, () => {
                observer.next(this.mapResourceItineraryContent(resource, { id: element, error: true }))
                observer.unsubscribe();
            })
        })
    }

    getResourceSupplier(resource:string, element:string, useDataPath?:boolean) {
        return new Observable<EFSupplier>((observer) => {
            resource = this.normalizeResourceName(resource);

            let _resourceId = resource.split('~')[0];

            this.http.get(this.environmentService.environment.APIUrl + '/' + (useDataPath ? 'data' : 'public') + '/' + _resourceId + '/' + element).subscribe((data) => {
                observer.next(new EFSupplier(data, resource))
                observer.unsubscribe();
            }, () => {
                observer.next(new EFSupplier({ error: true }, resource))
                observer.unsubscribe();
            })
        })
    }

    private _reqResources = ['experience','host','eatery','experience~Event', 'experienceSupplier','poi','itinerary','article'];

    isExperienceFinderResource(resource:string) {
        return this._reqResources.indexOf(this.normalizeResourceName(resource)) != -1;
    }

    isResourceProduct(product:EFProduct) {
        return this._reqResources.indexOf(product.resource) != -1;
    }

    private _mapMusementExperienceList(type:EFProductType, data:{ [id:string]:any }) {
        let _products:Array<EFProduct> = [];
    
        for(let i in data) {
            _products.push(new EFProduct(this.localizationService, type, this._checkMapProductData(data[i], 'musement:experience'), 'musement:experience', { origin: 'musement', resource: 'activities', id: i }))
        }

        return _products;
    }

    listProducts(filteredTypes?:Array<string>, filteredGroups?:Array<string>, realm?:string) {
        return new Observable<{ [type:string]: Array<EFProduct> }>((observer) => {
            let _reqByType:{ [type:string]: Array<Observable<Array<EFProduct>>> } = {}

            let _envResources = this.environmentService.environment.ExperienceFinderResources;

            for(let _resource of this._reqResources) {
                if(_envResources == null || _envResources.indexOf(_resource) != -1) {
                    let _type = this._checkMapProductType(_resource);

                    if(_reqByType[_type] == null) _reqByType[_type] = [];

                    _reqByType[_type].push(this.listResourceProducts(_resource, filteredGroups, null, realm))
                }
            }

            if(realm == null && this.environmentService.publicRealm == null && (filteredGroups == null || filteredGroups.length == 0)) { // solo i prodotti dalle resource hanno un realm o un group, se ci sono dei filtri per group escludo il resto
                if(_envResources != null) {
                    if(_envResources.indexOf('municipium:poi') != -1) {
                        let _type = this._checkMapProductType('municipium:poi');
        
                        if(_reqByType[_type] == null) _reqByType[_type] = [];
        
                        _reqByType[_type].push(this._getData(this.environmentService.environment.APIUrl + '/public/municipium/poi').pipe(map((data) => {
                            let _products:Array<EFProduct> = [];
        
                            if(data != null) {
                                for(let _item of data as Array<any>) {
                                    _products.push(new EFProduct(this.localizationService, _type, this._checkMapProductData(_item, 'municipium:poi'), 'municipium:poi', { origin: 'municipium', resource: 'poi', id: _item.id }))
                                }
                            }
        
                            return _products;
                        })))    
                    }
                    
                    if(_envResources.indexOf('municipium:event') != -1) {
                        let _type = this._checkMapProductType('municipium:event');
        
                        if(_reqByType[_type] == null) _reqByType[_type] = [];
        
                        _reqByType[_type].push(this._getData(this.environmentService.environment.APIUrl + '/public/municipium/event').pipe(map((data) => {
                            let _products:Array<EFProduct> = [];
        
                            if(data != null) {
                                let _sameTitle:{ [title:string]: any } = {}
                                
                                for(let _cData of data as Array<any>) {
                                    let _cTitle = _cData.title.toLowerCase();
        
                                    if(_sameTitle[_cTitle] == null) {
                                        _sameTitle[_cTitle] = _cData;
                                        _sameTitle[_cTitle].dates = [_cData.start_date]
                                    }
                                    else {
                                        if(_sameTitle[_cTitle].dates.indexOf(_cData.start_date) == -1) {
                                            _sameTitle[_cTitle].dates.push(_cData.start_date)
                                        }
                                    }
                                }
        
                                for(let i in _sameTitle) {
                                    _sameTitle[i].dates.sort();

                                    let _item = _sameTitle[i];
        
                                    _products.push(new EFProduct(this.localizationService, _type, this._checkMapProductData(_item, 'municipium:event'), 'municipium:event', { origin: 'municipium', resource: 'event', id: _item.id }))
                                }
                            }
        
                            return _products;
                        })))    
                    }
    
                    if(_envResources.indexOf('musement:experience') != -1) {
                        let _type = this._checkMapProductType('musement:experience');
        
                        if(_reqByType[_type] == null) _reqByType[_type] = [];
        
                        _reqByType[_type].push(new Observable<Array<EFProduct>>((observer) => {
                            this._getData(this.environmentService.environment.APIUrl + '/public/musement/activities').subscribe((data) => {
                                let _dataById:{ [id:string]:any } = {}
    
                                let _reqNum = 0;
    
                                if(data != null) {
                                    for(let _cData of data as Array<any>) {
                                        if(_cData.languages == null) _cData.languages = ['en_EN']
    
                                        for(let i = 0; i < _cData.languages.length; i++) {
                                            _cData.languages[i] = this.localizationService.normalizeLanguage(_cData.languages[i])
                                        }
    
                                        _cData.translations = []
    
                                        _cData.translations.push({
                                            language: 'en_EN',
                                            title: _cData.title,
                                            description: _cData.description,
                                            whats_included: _cData.whats_included,
                                            useful_information: _cData.useful_information,
                                        })
    
                                        if(_cData.category != null && typeof _cData.category == 'object') {
                                            _cData.category.translations = []
                                        }
    
                                        _dataById[_cData.id] = _cData
                                    }
    
                                    for(let _language of this.localizationService.availableApplicationLanguages) {
                                        if(_language != 'en_EN') {
                                            _reqNum++;
    
                                            this._getData(this.environmentService.environment.APIUrl + '/public/musement/activities?lang=' + _language.replace('_', '-')).subscribe((data) => {
                                                if(data != null) {
                                                    for(let _cData of data as Array<any>) {
                                                        let _product = _dataById[_cData.id];
    
                                                        if(_product != null) {
                                                            _product.translations.push({
                                                                language: _language,
                                                                title: _cData.title,
                                                                description: _cData.description,
                                                                whats_included: _cData.whats_included,
                                                                useful_information: _cData.useful_information,
                                                            })
    
                                                            if(_cData.category != null && typeof _cData.category == 'object' && _product.category != null) {
                                                                _product.category.translations.push({
                                                                    language: _language,
                                                                    name: _cData.category.name
                                                                })
                                                            }
                                                        }
                                                    }
                                                }
    
                                                _reqNum--;
                                                if(_reqNum == 0) {
                                                    observer.next(this._mapMusementExperienceList(_type, _dataById))
                                                    observer.complete();
                                                }
                                            }, () => {
                                                _reqNum--;
                                                if(_reqNum == 0) {
                                                    observer.next(this._mapMusementExperienceList(_type, _dataById))
                                                    observer.complete();
                                                }
                                            })
                                        }
                                    }
                                }
    
                                if(_reqNum == 0) {    
                                    observer.next(this._mapMusementExperienceList(_type, _dataById))
                                    observer.complete();
                                }
                            })
                        }))    
                    }
                }
    
                if(this.environmentService.environment.ExperienceFinderGeoJSON != null) {
    
                    for(let i in this.environmentService.environment.ExperienceFinderGeoJSON) {
                        let _type = 'utility'
    
                        if(_reqByType[_type] == null) _reqByType[_type] = [];
    
                        _reqByType[_type].push(this._getData(this.environmentService.environment.ExperienceFinderGeoJSON[i]).pipe(map((data:EFGeoJSON) => {
                            let _products:Array<EFProduct> = [];
    
                            if(data != null) {
                                for(let j = 0; j < data.features.length; j++) {
                                    let _cFeature = data.features[j];
                                    
                                    let _cLanguages:Array<string> = [];
                                    let _cTranslations:Array<any> = [];
                
                                    let _title:string = null;
                                    let _description:string = null;
                
                                    if(i == 'parking') {
                                        _title = _cFeature.properties.name;
                                        _description = '';
                
                                        if(_cFeature.properties.Tipo_d_uso != null && _cFeature.properties.Tipo_d_uso != '') {
                                            _description += _cFeature.properties.Tipo_d_uso;
                                        }
                
                                        if(_cFeature.properties.parking_spaces != null && _cFeature.properties.parking_spaces != 0) {
                                            if(_description != '') _description += ' - ';
                                            _description += 'Spaces: ' + _cFeature.properties.parking_spaces
                                        }
                                    }
                                    else if(i == 'charging-station') {
                                        _title = 'Charging station';
                                        _description = 'KW: ' + _cFeature.properties.kW + '<br/>Outlets: ' + _cFeature.properties.number_of_outlets;
                                    }
                
                                    for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                                        _cLanguages.push(_cLanguage);
                
                                        _cTranslations.push({
                                            language: _cLanguage,
                                            title: _title,
                                            description: _description
                                        })                                    
                                    }
                
                                    let _swap = _cFeature.geometry.coordinates[0];
                                    _cFeature.geometry.coordinates[0] = _cFeature.geometry.coordinates[1]
                                    _cFeature.geometry.coordinates[1] = _swap;
                
                                    _products.push(new EFProduct(this.localizationService, 'utility',  {
                                        id: 'utility_' + i + '_' + j,
                                        category: i,
                                        geolocation: _cFeature.geometry,
                                        address: _cFeature.properties.address,
                                        description: _description,
                                        languages: _cLanguages,
                                        translations: _cTranslations
                                    }, 'utility'))   
                                }
                            }
                
                            return _products;
                        })))
                    }
                }
            }

            let _retVal:{ [type:string]: Array<EFProduct> } = {}

            let _reqCount = 0;

            for(let i in _reqByType) {
                if((filteredTypes == null || filteredTypes.indexOf(i) != -1) && (_reqByType[i] != null && _reqByType[i].length > 0)) {
                    _retVal[i] = null;

                    let _typeReqCount = 0;

                    let _typeResults:Array<EFProduct> = [];

                    for(let _observable of _reqByType[i]) {
                        _reqCount++;
                        _typeReqCount++;
                        _observable.subscribe((data) => {
                            setTimeout(() => {
                                for(let _product of data) {
                                    _typeResults.push(_product)
                                }
                                
                                _typeReqCount--
                                if(_typeReqCount == 0) {
                                    _retVal[i] = _typeResults;
                                    observer.next(_retVal)
                                }
    
                                _reqCount--
                                if(_reqCount == 0) {
                                    observer.complete()
                                    observer.unsubscribe()
                                }
                            }, 1000)
                        })
                    }
                }
            }

            if(_reqCount == 0) {
                observer.complete()
                observer.unsubscribe()
            }
        })
    }

    private _getData(url:string) {
        return new Observable<any>((observer) => {
            this.http.get(url).subscribe((data:any) => {
                observer.next(data);
                observer.unsubscribe();
            })
        })
    }

    getProductDetail(product:EFProduct) {
        return new Observable<EFProduct>((observer) => {
            if((product.resource == 'municipium:poi' || product.resource == 'municipium:event') && product.base_api != null) {
                let _apiResource = null;
                if(product.resource == 'municipium:poi') _apiResource = 'point_of_interests'
                else if(product.resource == 'municipium:event') _apiResource = 'events'

                let _apiId = product.id.replace(product.resource + '_', '')

                this.http.get(product.base_api + '/' + _apiResource + '/' + _apiId).subscribe((data:any) => {
                    // TODO: questa cosa è da sistemare, non posso tirarmi dentro i dati così
                    data.base_api = product.base_api;
                    data.dates = product.dates;
                    observer.next(new EFProduct(this.localizationService, this._checkMapProductType(product.resource), this._checkMapProductData(data, product.resource), product.resource, product.analytics));
                    observer.unsubscribe();
                }, (error) => {
                    observer.next(product);
                    observer.unsubscribe();
                })
            }
            else {
                setTimeout(() => {
                    observer.next(product);
                    observer.unsubscribe();
                }, 100)
            }
        })
    }

    getItinerary(id:string, useDataPath?:boolean) {
        return new Observable<EFItinerary>((observer) => {
            this.http.get(this.environmentService.environment.APIUrl + '/' + (useDataPath ? 'data' : 'public') + '/itinerary/' + id).subscribe((data) => {
                observer.next(new EFItinerary(data, this, useDataPath))
                observer.unsubscribe();
            })
        })
    }

    private _applyProductsFilterDoesProductMatchLanguages(product:EFProduct, languages:{ [lang:string]: boolean }) {
        if(languages == null || Object.keys(languages).length == 0) {
            return true;
        }
        else {
            for(let i in languages) {
                if(languages[i] && product.languages.indexOf(i) != -1) {
                    return true;
                }
            }
            
            return false;
        }
    }

    private _applyProductsFilterDoesProductMatchGroups(product:EFProduct, groups:Array<string>) {  
        if(groups == null) {
            return true;
        }
        else {
            if(product.group_id == null) {
                return false;
            }
            else {
                for(let _group of groups) {
                    if(product.group_id == _group) {
                        return true;
                    }
                }

                return false;
            }
        }
    }

    private _applyProductsFilterDoesProductMatchTags(product:EFProduct, tags:Array<string>) {  
        if(tags == null) {
            return true;
        }
        else {
            for(let _tag of tags) {
                if(product.tags == null || product.tags.indexOf(_tag.toLowerCase()) == -1) {
                    return false;
                }
            }

            return true;
        }
    }

    private _applyProductsFilterDoesProductMatchInterval(product:EFProduct, interval:{ from: string, to: string }) {
        if(interval == null) return true;
        else {
            let _cDate:Date = new Date(interval.from);
            let _cLastDate:Date = new Date(interval.to);
    
            if(product.dates != null && product.dates.length > 0) {
                for(let _productDate of product.dates) {
                    let _checkDates:Array<Date> = [];

                    if(typeof _productDate == 'string') {
                        _checkDates.push(new Date(_productDate))
                    }
                    else {
                        _checkDates.push(new Date(_productDate.begin))
                        _checkDates.push(new Date(_productDate.end))
                    }
    
                    for(let _date of _checkDates) {
                        if(_date.getTime() > _cDate.getTime() && _date.getTime() < _cLastDate.getTime()) {
                            return true;
                        }
                    }
                }
            }
            else if(product.availability != null) {
                while(_cDate <= _cLastDate) {
                    if(product.availability.isAvailableOn(_cDate.getFullYear(), _cDate.getMonth() + 1, _cDate.getDate())) {
                        return true
                    }
    
                    _cDate.setDate(_cDate.getDate() + 1);
                }
            }
        }
    }

    private _applyProductsFilterDoesProductMatchStars(product:EFProduct, stars:{ [stars:number]: boolean }) {
        if(stars == null) return true;
        else {
            return stars[product.stars] == true;
        }
    }

    private _applyProductsFilterDoesProductMatchCost(product:EFProduct, cost:Array<{ from: number, to: number, selected: boolean }>) {
        if(cost == null) return true;
        else {
            for(let _range of cost) {
                if(_range.selected) {
                    if(product.cost_list != null) {
                        for(let _item of product.cost_list) {
                            if(_range.from <= _item && _range.to >= _item) return true;
                        }
                    }
                }
            }
        }
    }

    private _applyProductsFilterDoesProductMatchIds(product:EFProduct, ids:Array<string>) {
        if(ids == null || ids.length == 0) return true;
        else return ids.indexOf(product.id) != -1;
    }

    applyProductsFilter(productsData:Array<EFProduct>, filterData:EFFilterData, presetCategories?:{ [type:string]: boolean }) {
        let _retval:Array<EFProduct> = [];

        for(let _cProduct of productsData) {
            if(filterData.type == null || filterData.type == _cProduct.type) {
                let _isIn = true;

                if(filterData.category != null) {
                    // se ci sono delle categorie presettate dall'esterno devo visualizzare sempre solo i prodotti con la categoria settata
                    if((presetCategories != null && presetCategories[_cProduct.type]) || !(filterData.allCategories && filterData.allSubcategories)) {
                        if(_cProduct.category != null) {
                            let _hasCategorySelected = false;
                            let _hasSubcategorySelected = false;

                            for(let _category of _cProduct.category.split(',')) {
                                if(filterData.allCategories || filterData.category[filterData.type][_category]) {
                                    _hasCategorySelected = true;

                                    if(filterData.allSubcategories) {
                                        _hasSubcategorySelected = true;
                                    }
                                    else {
                                        if(filterData.subcategory[filterData.type][_category] != null) {
                                            if(_cProduct.subcategory != null) {
                                                for(let _subcategory of _cProduct.subcategory.split(',')) {
                                                    if(filterData.subcategory[filterData.type][_category][_subcategory]) {
                                                        _hasSubcategorySelected = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            _isIn = _isIn && _hasCategorySelected && _hasSubcategorySelected;
                        }
                    }
                }

                let _languageFilter = filterData.language[filterData.type]
                if(_languageFilter == null && filterData.language['*'] != null) _languageFilter = filterData.language['*'];

                _isIn = _isIn && this._applyProductsFilterDoesProductMatchLanguages(_cProduct, _languageFilter);

                if(filterData.stars[filterData.type] != null && !filterData.allStars) _isIn = _isIn && this._applyProductsFilterDoesProductMatchStars(_cProduct, filterData.stars[filterData.type]);
                if(filterData.cost[filterData.type] != null && !filterData.allCosts) _isIn = _isIn && this._applyProductsFilterDoesProductMatchCost(_cProduct, filterData.cost[filterData.type]);
                
                _isIn = _isIn && this._applyProductsFilterDoesProductMatchGroups(_cProduct, filterData.groups);
                _isIn = _isIn && this._applyProductsFilterDoesProductMatchTags(_cProduct, filterData.tags);
                _isIn = _isIn && this._applyProductsFilterDoesProductMatchInterval(_cProduct, filterData.interval);
                _isIn = _isIn && this._applyProductsFilterDoesProductMatchIds(_cProduct, filterData.ids);
    
                if(_isIn) {
                    _retval.push(_cProduct)
                }
            }
        } 

        return _retval;
    }
}