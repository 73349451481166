<div #mainContainer class="EFMain EFMain--{{appMode == null ? '' : appMode.toUpperCase()}} {{ isPortrait ? 'EFMain--Portrait' : 'EFMain--Landscape' }}" (click)="resetInactivity()" (mousemove)="resetInactivity()">
    <app-pg-loading *ngIf="isLoading || isLoadingDetail" [isLarge]="true" [isOverlay]="true"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div class="bg-light">
            <button *ngIf="hasBack" class="btn btn-white EFMain-Back" (click)="goBack.emit()">
                <i class="fa fa-chevron-left"></i>
            </button>

            <div class="EFMain-Center">
                <div class="EFMain-Section">
                    <button *ngFor="let section of ['map','list','calendar']" class="btn btn-white EFMain-Section-Button {{ currentSection == section ? 'btn-white-selected' : '' }}" (click)="goToSection(section)">
                        <span>{{ 'experience-finder.ef-main.nav-' + section | locTranslate }}</span>
                    </button>
                </div>
                <div class="EFMain-Content">
                    <div class="EFMain-Content-Main">
                        <div class="EFMain-Content-Main-Center">
                            <ng-container *ngIf="!modalProductTarget || !modalProductTarget.hasItinerary()">
                                <div class="EFMain-Content-Filter {{ currentSection == 'map' ? 'EFMain-Content-Filter--Overlay' : 'container p-0'}}">
                                    <app-ef-filter #filterComponent [(filterData)]="filterData" (filterDataChange)="onFilterDataChange()" [lockFilter]="lockFilter" [lockFilterType]="lockFilterType" [section]="currentSection" [availableTypes]="availableTypes" (centerMap)="centerMap()" [isLoadingTabs]="isLoadingProducts"></app-ef-filter>
                                </div>
    
                                <div class="EFMain-Content-Map">
                                    <app-ef-map #efMapComponent [hidden]="currentSection != 'map'" [productsData]="getCurrentProductsData()" [productsHighlight]="productsHighlight" (showProduct)="onShowProduct($event)"
                                    (mapBoundsChange)="onMapBoundsChange($event)" [mapBounds]="mapBounds"></app-ef-map>
                                </div>
    
                                <div *ngIf="currentSection == 'list'" class="EFMain-Content-List">
                                    <div class="container p-0">
                                        <app-ef-list [full]="true" [productsData]="filteredProductsData" (showProduct)="onShowProduct($event)"></app-ef-list>
                                    </div>
                                </div>
    
                                <div *ngIf="currentSection == 'calendar'" class="EFMain-Content-Calendar">
                                    <div class="container p-0">
                                        <app-ef-calendar *ngIf="currentSection == 'calendar'" [productsData]="filteredProductsData" (showProduct)="onShowProduct($event)"></app-ef-calendar>
                                    </div>
                                </div>
                            </ng-container>
    
                            <div *ngIf="modalProductTarget && modalProductTarget.hasItinerary()" class="EFMain-Content-Itinerary">
                                <app-ef-product-map [productData]="modalProductTarget" (showProduct)="onShowProduct(modalProductTarget.id + '/' + $event)"></app-ef-product-map>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentSection == 'map' || isLoadingDetail || modalProductTarget" class="EFMain-Side">
                <div *ngIf="getCurrentProductsData(true); let productsData" class="bg-light">
                    <app-ef-list [vertical]="!isPortrait" [productsData]="productsData" (showProduct)="onShowProduct($event)"></app-ef-list>
                </div>
            </div>
        </div> 
        
        <div *ngIf="modalProductTarget" class="EFModal EFModal--Product bg-light {{ !isPortrait ? 'EFModal--Lanscape' : '' }} pg-animation-slideup">    
            <div class="EFModal-Body">
                <app-ef-product [productData]="modalProductTarget" [supplierData]="modalSupplierTarget" [POIData]="modalPOITarget" [appMode]="appMode" (showProduct)="onShowProduct($event)" [oneColumn]="true" [hasBack]="true" (goBack)="modalProductBack()"></app-ef-product>
            </div>
        </div>
    </ng-container>

    <div *ngIf="showScreensaver" class="EFMain-Screensaver">
        <app-ef-screensaver [productsData]="productsData"></app-ef-screensaver>
    </div>
</div>
