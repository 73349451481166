import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PgFormField, PgFormLayout } from '../../../models/form.model';
import { DataService } from '../../../services/data.service';
import { LocalizationService } from '../../../services/localization.service';
import { NotificationsService } from '../../../services/notifications.service';
import { OptionMapsService } from '../../../services/option-maps.service';
import { AuthService } from '../../../services/auth.service';
import { SaveStatusService } from '../../../pg-ui-elements/save-status.service';
import { EnvironmentService } from '../../../services/environment.service';
import { SingleFormComponent } from '../single-form-main';
import { PGUtilities } from '../../../pg-utilities';

@Component({
  selector: 'app-forms-article',
  templateUrl: './forms-article.component.html',
  styleUrls: ['./forms-article.component.scss']
})
export class FormsArticleComponent extends SingleFormComponent {

    constructor(protected dataService:DataService, protected authService:AuthService, protected localizationService:LocalizationService, protected router:Router, protected route:ActivatedRoute, protected notificationsService:NotificationsService, protected saveStatusService:SaveStatusService, protected optionMapsService:OptionMapsService, protected environmentService:EnvironmentService) {
        super(dataService, authService, localizationService, router, route, notificationsService, saveStatusService, optionMapsService, environmentService)
    }

    resourceId = 'Article';

    private _POIOptions:Array<{ value:string, text: string, order: number, geolocation: any }> = null;

    formLayout = new PgFormLayout([
        new PgFormField({ label: 'auto', type: 'string', name: 'title@ArticleTranslation', locale: true, required: true, maxLength: 100 }),
        new PgFormField({ label: 'auto', type: 'string', name: 'overtitle@ArticleTranslation', locale: true, required: true, maxLength: 200 }),
        new PgFormField({ label: 'auto', type: 'string', name: 'subtitle@ArticleTranslation', locale: true, required: true, maxLength: 200 }),
        new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
        new PgFormField({ label: 'auto', type: 'file', name: 'cover', fileType: 'images', required: true }),
        new PgFormField({ label: 'auto', type: 'file', name: 'images', multi: true, fileType: 'images' }),
        new PgFormField({ label: 'auto', type: 'file', name: 'videos', multi: true, fileType: 'videos' }),
        new PgFormField({ label: 'auto', type: 'html', name: 'text@ArticleTranslation', locale: true, required: true }),
        new PgFormField({ label: 'auto', type: 'select', name: 'poi_id', multi: true, required: true }),
        new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', readonly: true, 
            slaves: {
                'country': 'country',
                'region': 'region',
                'province': 'province',
                'city': 'city',
                'address': 'address',
                'zipcode': 'zipcode',
                //'timezone': 'timezone'
            } 
        }),
        new PgFormField({ label: 'auto', type: 'string', name: 'location_description@ArticleTranslation', locale: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
        //new PgFormField({ label: 'auto', type: 'string', name: 'timezone', readonly: true, default: 'Europe/Rome' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
        new PgFormField({ label: 'auto', type: 'json', name: 'paragraphs' }),
        new PgFormField({ label: 'auto', type: 'json', name: 'external_poi' }),
    ]);

    externalPoiFields = [
        new PgFormField({ label: 'auto', type: 'string', name: 'name' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'url' }),
    ]

    paragraphFields = [
        new PgFormField({ label: 'auto', type: 'string', name: 'title', locale: true, maxLength: 200 }),
        new PgFormField({ label: 'auto', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'auto', type: 'html', name: 'text', locale: true, htmlMode: 'inline-only'  }),
        new PgFormField({ label: 'auto', type: 'file', name: 'images', multi: true, fileType: 'images' }),
    ]

    paragraphLinkFields = [
        new PgFormField({ label: 'auto', type: 'string', name: 'name' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'url' }),
    ]

    protected afterInitializeForm() {
        return new Promise<void>((resolve, reject) => {
            this.formLayout.getFieldLayout('tags').display = { fullWidth: true }

            for(let _field of ['country','region','province','city','address','zipcode'/*,'timezone'*/]) {
                this.formLayout.getFieldByName(_field).visible = false;
            }

            this.formLayout.getFieldByName('priority').visible = false;
            this.formLayout.getFieldByName('paragraphs').visible = false;
            this.formLayout.getFieldByName('external_poi').visible = false;

            this.formLayout.getFieldLayout('poi_id').display = { oneLine: true }
            
            for(let _field of this.paragraphFields) {
                if(_field.label == 'auto') {
                    _field.label = 'forms-article.paragraphs-field-' + _field.name
                }
            }

            for(let _field of this.paragraphLinkFields) {
                if(_field.label == 'auto') {
                    _field.label = 'forms-article.paragraphs-links-field-' + _field.name
                }
            }            
    
            for(let _field of this.externalPoiFields) {
                if(_field.label == 'auto') {
                    _field.label = 'forms-article.external-poi-field-' + _field.name
                }
            }

            this._POIOptions = [];
    
            this.dataService.getResourceData('Poi', { limit: 1000 }).subscribe((data) => {    
                for(let _cData of data) {
                    this._POIOptions.push({ value: _cData.id, text: _cData.name, order: _cData.id, geolocation: _cData.geolocation })
                }
    
                this._POIOptions.sort((a, b) => {
                    if(a.order != b.order) {
                        if(a.order < b.order) return -1;
                        else if(a.order > b.order) return 1;
                    }
                    else {
                        if(a.text < b.text) return -1;
                        else if(a.text > b.text) return 1;
                        else return 0;
                    }
                })
    
                this.formLayout.getFieldByName('poi_id').options = this._POIOptions;
                
                resolve();
            })
        })
    }

    protected adaptLoadData(values: any) {
        if(values?.poi_id) {
            if(typeof values.poi_id == 'object') {
                values.poi_id = JSON.stringify(values.poi_id)
            }

            if(!/^\[[^\]]*\]$/.test(values.poi_id)) {
                values.poi_id = JSON.stringify([values.poi_id])
            }
        }

        return values;
    }

    protected adaptSaveData(values: any) {
        if(values.poi_id != null) {
            values.poi_id = JSON.parse(values.poi_id)
        }

        // Migrazione language - languages
        values.language = null;

        return values;
    }

    private _lastPoi = null;
    private _lastPoiTimeout = null;

    formLanguages:Array<string> = null;

    paragraphsList:Array<any> = null;

    externalPoiList:Array<any> = null;

    protected async afterLoadData(values:any) {
        this._lastPoi = values?.poi_id;

        this.paragraphsList = PGUtilities.tryParseJSON(values.paragraphs)
        if(this.paragraphsList == null) this.paragraphsList = [];

        for(let _paragraph of this.paragraphsList) {
            if(_paragraph.links == null) _paragraph.links = [];
        }

        this.externalPoiList = PGUtilities.tryParseJSON(values.external_poi)
        if(this.externalPoiList == null) this.externalPoiList = [];

        if(values.language != null) { // Migrazione language - languages
            values.languages = JSON.stringify([values.language])
            for(let _field of this.formLayout.fieldList) {
                if(/@/.test(_field.name)) {
                    let _key = _field.name.replace(/@.*$/, '');
                    
                    let _val:any = {}
                    _val[values.language] = values[_key]

                    values[_field.name] = JSON.stringify(_val)

                    delete values[_key];
                }
            }

            for(let _item of values.paragraphs) {                
                for(let _field of this.paragraphFields) {
                    if(_field.locale) {
                        let _val:any = {}
                        _val[values.language] = _item[_field.name]
    
                        _item[_field.name] = JSON.stringify(_val)
                    }
                }
            }

            values.language = null;
        }

        this._checkLanguages(values)

        return values;
    }

    beforeValueChanges(values:any, changes:any) {
        if(values.poi_id != this._lastPoi) {
            this._lastPoi = values.poi_id;

            values.geolocation = null;

            clearTimeout(this._lastPoiTimeout)
            this._lastPoiTimeout = setTimeout(() => { // non sono riuscito a risolvere diversamente dal mettere una setTimeout...
                let _poiList = PGUtilities.tryParseJSON(values.poi_id)

                if(_poiList == null || _poiList.length != 1) {
                    this.formLayout.setData({ geolocation: { _forceChanges: true } })
                }
                else {
                    let _poiId = _poiList[0];

                    for(let _POI of this._POIOptions) {
                        if(_POI.value == _poiId) {
                            let _values = JSON.parse(JSON.stringify({ geolocation: _POI.geolocation }))
                            _values.geolocation._forceChanges = true; // ...e non sono riuscito a risolvere diversamente dal fare questo hack per triggerare la change sul campo
                            this.formLayout.setData(_values)

                            break;
                        }
                    }

                }
            }, 100)
        }

        this._checkLanguages(values)

        return values;
    }

    private _checkLanguages(values:any) {
        this.formLanguages = PGUtilities.tryParseJSON(values.languages)
        if(this.formLanguages == null) this.formLanguages = [];
    }

    onParagraphValueChange() {
        this.formLayout.setData({ paragraphs: JSON.stringify(this.paragraphsList) })
    }

    addParagraph() {
        this.paragraphsList.push({ links: [] })
        this.onParagraphValueChange();
    }

    deleteParagraph(paragraph:any) {
        let _index = this.paragraphsList.indexOf(paragraph);
        
        if(_index != -1) {
            this.paragraphsList.splice(_index, 1)
            this.onParagraphValueChange();
        }
    }

    moveParagraphPrev(paragraph:any) {
        let _index = this.paragraphsList.indexOf(paragraph);
        
        if(_index != -1 && _index > 0) {
            let _swap = this.paragraphsList[_index];
            this.paragraphsList[_index] = this.paragraphsList[_index - 1];
            this.paragraphsList[_index - 1] = _swap
            this.onParagraphValueChange();
        }
    }

    moveParagraphNext(paragraph:any) {
        let _index = this.paragraphsList.indexOf(paragraph);
        
        if(_index != -1 && _index < this.paragraphsList.length - 1) {
            let _swap = this.paragraphsList[_index];
            this.paragraphsList[_index] = this.paragraphsList[_index + 1];
            this.paragraphsList[_index + 1] = _swap
            this.onParagraphValueChange();
        }
    }

    delParagraphLink(paragraph:any, link:any) {
        let _index = paragraph.links.indexOf(link)

        if(_index != -1) {
            paragraph.links.splice(_index, 1)
            this.onParagraphValueChange();
        }
    }

    addParagraphLink(paragraph:any) {
        paragraph.links.push({})
        this.onParagraphValueChange();
    }
    
    onExternalPoiValueChange() {
        this.formLayout.setData({ external_poi: JSON.stringify(this.externalPoiList) })
    }

    addExternalPoi() {
        this.externalPoiList.push({ })
        this.onExternalPoiValueChange();
    }

    delExternalPoi(poi:any) {
        let _index = this.externalPoiList.indexOf(poi);
        
        if(_index != -1) {
            this.externalPoiList.splice(_index, 1)
            this.onExternalPoiValueChange();
        }
    }
}
