<div class="PGCalendar {{isLoading ? 'PGCalendar--IsLoading' : ''}} {{isFirstLoading ? 'PGCalendar--IsFirstLoading' : ''}} border rounded">
    <div *ngIf="isLoading" class="PGCalendar-Loading">
        <app-pg-loading *ngIf="isLoading" [isOverlay]="true"></app-pg-loading>
    </div>
    <div class="PGCalendar-Header">
        <div class="PGCalendar-Header-Controls border-bottom">
            <div class="PGCalendar-Header-Controls-Left">
                <!--<button class="btn btn-primary btn-sm me-1" (click)="year = year - 1"><i class="fa-regular fa-angle-double-left"></i></button>-->
                <button *ngIf="!notAvailable && !isFirstMonth" class="btn btn-link btn-sm" [disabled]="isLoading" (click)="month = month - 1"><i class="fa-regular fa-chevron-left"></i></button>
            </div>
            <div class="PGCalendar-Header-Controls-Center">
                <ng-container *ngIf="!isFirstLoading">
                    <h5 *ngIf="isFirstMonth" class="py-1">{{ 'pg-availability-select.next-availability' | locTranslate }}
                        <ng-container *ngIf="minDate != null"> {{ 'pg-availability-select.next-availability-from' | locTranslate }} {{localizationService.data.monthNames[month]}} {{year}}</ng-container>
                    </h5>
                    <h5 *ngIf="!isFirstMonth" class="py-1">{{localizationService.data.monthNames[month]}} {{year}}</h5>
                </ng-container>
                <!--
                <select [(ngModel)]="month" class="form-control form-control-sm me-1">
                    <option *ngFor="let monthIndex of [0,1,2,3,4,5,6,7,8,9,10,11]" value="{{monthIndex}}">{{localizationService.data.monthNames[monthIndex]}}</option>
                </select>
                <select [(ngModel)]="year" class="form-control form-control-sm">
                    <option *ngFor="let yearOffset of [-5,-4,-3,-2,-1,0,1,2,3,4,5]" value="{{year + yearOffset}}">{{year + yearOffset}}</option>
                </select>
                -->
            </div>
            <div class="PGCalendar-Header-Controls-Right">
                <button *ngIf="!notAvailable" class="btn btn-link btn-sm" [disabled]="isLoading" (click)="month = month + 1"><i class="fa-regular fa-chevron-right"></i></button>
                <!--<button class="btn btn-primary btn-sm ms-1" (click)="year = year + 1"><i class="fa-regular fa-angle-double-right"></i></button>-->
            </div>
        </div>
        <div class="PGCalendar-Header-Days border-bottom">
            <div *ngFor="let day of [0,1,2,3,4,5,6]">
                {{localizationService.data.dayNames[(day + localizationService.data.weekStart) % 7].substring(0, 3)}}
            </div>
        </div>
    </div>
    <div *ngIf="notAvailable" class="PGCalendar-Content text-muted text-center p-3">
        <h5 class="m-3">{{ 'pg-availability-select.not-available' | locTranslate }}</h5>
    </div>
    <div *ngIf="!notAvailable && weeks != null" class="PGCalendar-Content">
        <div *ngFor="let week of weeks; let i = index;" class="PGCalendar-Content-Week">
            <div *ngFor="let day of week;  let j = index;" class="PGCalendar-Content-Week-Day {{getDayFlagClass(day)}} border {{getBorderClass(i, j)}}" (click)="setSelectedDay(day)" (mouseenter)="onMouseEnter(day)" (mouseleave)="onMouseLeave(day)">
                <div class="PGCalendar-Content-Week-Day-Background {{getDayBackgroundClass(day)}}"></div>
                <div *ngIf="day != null" class="PGCalendar-Content-Week-Day-Foreground">
                    <span class="PGCalendar-Content-Week-Day-Title {{getDayTitleClass(day)}}">
                        <b *ngIf="mustDayShowMonth(i, j)">{{day.date.day}} {{localizationService.data.monthNames[day.date.month].substring(0, 3)}}</b>
                        <span *ngIf="!mustDayShowMonth(i, j)">{{day.date.day}}</span>
                    </span>
                    <div class="PGCalendar-Content-Week-Day-Events text-muted">
                        <span *ngIf="day.elements.length > 0">
                            <div class="PGCalendar-Content-Week-Day-Events-Element">
                                <span *ngIf="getDayAvailabilityStatusText(day); let text;" class="PGCalendar-Content-Week-Day-Events-Element-Text">{{text}}</span>
                                <span class="bg-{{getDayAvailabilityStatusClass(day)}} PGCalendar-Content-Week-Day-Events-Element-Status"></span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>    
        </div>
    </div>

    <div *ngIf="selectedDay != null &amp;&amp; selectedDay.elements != null &amp;&amp; selectedDay.elements.length > 0" class="PGCalendar-Detail border" appPgAnimations="Grow;origin:{{getDetailAnimationOrigin()}}">
        <div class="PGCalendar-Detail-Header px-2 border-bottom">
            <span>{{localizationService.data.dayNames[selectedDay.date.weekday]}} {{selectedDay.date.day}} {{localizationService.data.monthNames[selectedDay.date.month]}} {{selectedDay.date.year}}</span>
            <button class="btn btn-link" (click)="selectedDay = null"><i class="fa-regular fa-times"></i></button>
        </div>
        <div class="PGCalendar-Detail-Content">
            <div class="m-2">
                <div *ngIf="globalTimezone != null && globalTimezone != currentTimezone" class="PGCalendar-Detail-Content-Timezones px-2 m-1 border border-white">
                    <div>
                        <span>{{ 'pg-availability-select.timezone' | locTranslate }} {{globalTimezone}}</span>
                    </div>
                    <div>
                        <span>{{ 'pg-availability-select.timezone' | locTranslate }} {{currentTimezone}}</span>
                    </div>
                    <div class="PGCalendar-Detail-Content-Timezones-Button {{availabilityType == 'product' ? 'PGCalendar-Detail-Content-Timezones-Button--Product' : ''}}">
                    </div>
                </div>
                <button *ngFor="let element of selectedDay.elements" [disabled]="isElementUnavailable(element)" class="btn btn-block border mb-2 PGCalendar-Detail-Content-Element {{element == selectedAvailability ? 'btn-primary PGCalendar-Detail-Content-Element--Selected' : ''}}" appPgAnimations="FadeIn" (click)="setSelectedAvailability(element)">
                    <div class="PGCalendar-Detail-Content-Element-Header">
                        <div class="PGCalendar-Detail-Content-Element-Header-Timespan">
                            <div>
                                <span *ngIf="element.end != null" [innerHTML]="'pg-availability-select.time-from-to' | locTranslate:{ from: getElementTime(element, 'begin'), to: getElementTime(element, 'end') }"></span>
                                <span *ngIf="element.end == null" [innerHTML]="'pg-availability-select.time-at' | locTranslate:{ at: getElementTime(element, 'begin') }"></span>
                                <small *ngIf="globalTimezone == null && element.timezone != null && element.timezone != currentTimezone" class="ms-1">({{ 'pg-availability-select.timezone' | locTranslate }} {{element.timezone}})</small>
                            </div>

                            <div *ngIf="element.timezone != currentTimezone">
                                <span *ngIf="element.end != null" [innerHTML]="'pg-availability-select.time-from-to' | locTranslate:{ from: getElementTime(element, 'begin', true), to: getElementTime(element, 'end', true) }"></span>
                                <span *ngIf="element.end == null" [innerHTML]="'pg-availability-select.time-at' | locTranslate:{ at: getElementTime(element, 'begin', true) }"></span>
                            </div>

                            <!--<small class="ms-1 text-muted">id: {{element.id}}</small>-->

                            <div class="PGCalendar-Detail-Content-Element-Header-Timespan-Button {{availabilityType == 'product' ? 'PGCalendar-Detail-Content-Element-Header-Timespan-Button--Product' : ''}}">
                                <ng-container *ngIf="element.availability != null">
                                    <span *ngIf="availabilityType == 'product'" class="opacity-80">
                                        {{ 'pg-availability-select.available-slots' | locTranslate:{ num: formatCurrentAvailability(element) } }}
                                    </span>
                                    <i class="fa-regular fa-chevron-right"></i>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <div class="PGCalendar-Detail-Footer px-2 border-top">
            <div class="PGCalendar-Detail-Footer-Title my-1">
                <b>{{ 'pg-availability-select.dates-in' | locTranslate:{ month: localizationService.data.monthNames[selectedDay.date.month], year: selectedDay.date.year } }}</b>
            </div>
            <ul class="pagination pagination-sm mb-2">
                <li *ngIf="daysWithElementsAroundBefore" class="page-item disabled">
                    <a class="page-link">...</a>
                </li>
                <li *ngFor="let day of daysWithElementsAround" class="page-item {{selectedDay == day ? 'active' : ''}}" (click)="setSelectedDay(day)">
                    <a class="page-link" href="javascript:void(0)">
                        <div>{{day.date.day}}</div>
                    </a>
                </li>
                <li *ngIf="daysWithElementsAroundAfter" class="page-item disabled">
                    <a class="page-link">...</a>
                </li>
            </ul>
        </div>
    </div>
</div>
