import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-pg-group-picker',
  templateUrl: './pg-group-picker.component.html',
  styleUrls: ['./pg-group-picker.component.scss']
})
export class PgGroupPickerComponent implements OnInit {

    selectOptions:Array<{ value:string, text:string, disabled?:boolean }> = []
    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private authService:AuthService, private configService:ConfigService) { }

    ngOnInit(): void {
        if(this.configService.groups != null) {
            for(let _group of this.configService.groups) {
                let _isDisabled = true;

                for(let _userGroup of this.authService.user.groups) {
                   if(_userGroup.id == _group.id) {
                        _isDisabled = false;
                        break
                   }
                }

                this.selectOptions.push({ value: _group.id, text: _group.id + ' - ' + _group.label, disabled: _isDisabled })
            }
        }
        else {
            for(let _group of this.authService.user.groups) {
                this.selectOptions.push({ value: _group.id, text: _group.id + ' - ' + _group.label })
            }
        }
    }

}
