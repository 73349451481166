
<span class="PGSelect {{readonly ? 'PGSelect--ReadOnly' : 'PGSelect--NotReadOnly'}}">
    <app-pg-loading *ngIf="viewAs == null"></app-pg-loading>
    <span *ngIf="viewAs == 'checkboxes'" class="PGSelect-Checkboxes input-group">
        <div>
            <ng-container *ngIf="getVisibleOptions(); let visibleOptions">
                <label *ngFor="let option of visibleOptions" class="PGSelect-Checkboxes-Item">
                    <app-pg-switch [readonly]="readonly || isOptionDisabled(option)" [small]="true" [little]="true" [ngModel]="isOptionSelected(option)" (ngModelChange)="onSwitchChange($event, option)"></app-pg-switch>
                    <span [innerHTML]="option.text"></span>
                </label>
            </ng-container>
        </div>
    </span>
    <span *ngIf="viewAs == 'radios'" class="PGSelect-Radios input-group">
        <div>
            <ng-container *ngIf="getVisibleOptions(); let visibleOptions">
                <label *ngFor="let option of visibleOptions" class="PGSelect-Radios-Item">
                    <app-pg-switch [readonly]="readonly || isOptionDisabled(option)" [small]="true" [little]="true" [value]="option.value" [ngModel]="value" (ngModelChange)="onSwitchChange($event, option)"></app-pg-switch>
                    <span [innerHTML]="option.text"></span>
                </label>
            </ng-container>
        </div>
    </span>
    <span *ngIf="viewAs == 'select'" ngbDropdown [autoClose]="false" class="PGSelect-Select">
        <div ngbDropdownAnchor class="position-relative">
            <input #inputElement class="form-control PGSelect-Select-Input {{hasFocus && !hasSearch ? 'focus' : ''}}" 
                readonly="readonly" [tabIndex]="hasSearch ? -1 : null" [(ngModel)]="text" 
                (focus)="onInputFocus()" (focusout)="onInputBlur()" (keypress)="onKeyPress($event)"/>
            <input *ngIf="hasSearch" #searchInputElement placeholder="{{ 'pg-select.search' | locTranslate }}"
                class="form-control PGSelect-Select-Search {{hasFocus ? 'PGSelect-Select-Search--ShowSearch' : ''}}" 
                [readonly]="readonly" [tabIndex]="!hasSearch ? -1 : null" [(ngModel)]="search"
                (focus)="onInputFocus()" (focusout)="onInputBlur()" (keypress)="onKeyPress($event)"/>
            <div class="PGSelect-Select-Wrap">
                <div class="PGSelect-Select-Display border">
                    <span class="{{hasFocus && hasSearch ? 'd-none' : ''}} {{ !text ? 'text-muted' : '' }}" [innerHTML]="text || placeholder || '-'"></span>
                </div>
                <div class="PGSelect-Select-Button input-group-text">
                    <i class="fa-regular fa-angle-down"></i>
                </div>
            </div>
        </div>
        <app-pg-loading *ngIf="isLoading" pgAnimations="FadeIn"></app-pg-loading>
        <div ngbDropdownMenu class="dropdown-menu PGSelect-Select-Dropdown {{multi && selectedOptions.length > 0  ? 'PGSelect-Select-Dropdown--HasMulti' : ''}} {{isDropdownClosing ? 'pg-animation-fadeout' : 'pg-animation-fadein'}}">
            <span class="PGSelect-Select-Dropdown-Multi border-bottom">
                <div *ngFor="let selected of selectedOptions" class="dropdown-item" (mousedown)="deselectOption(selected)">
                    <span [innerHTML]="selected.text"></span>
                    <span class="ms-2">
                        <i class="fa-regular fa-times text-danger m-0"></i>
                    </span>
                </div>
            </span>
            <span class="PGSelect-Select-Dropdown-Options">
                <app-pg-loading *ngIf="isDropdownLoading" pgAnimations="FadeIn" [isOverlay]="true"></app-pg-loading>
                <!-- NB: sulle options ho usato il mousedown come evento perché il click dava problemi "strani" -->
                <div *ngIf="!required && !multi" class="dropdown-item" (mousedown)="selectOption()" [innerHTML]="display?.noSelectionText || '-'"></div>
                <ng-container *ngIf="getVisibleOptions(); let visibleOptions">
                    <div *ngIf="visibleOptions.length == 0" class="dropdown-item disabled">
                        <ng-container *ngIf="showSearchMin()">{{ 'pg-select.search-min' | locTranslate:{ min: searchMin } }}</ng-container>
                        <ng-container *ngIf="!showSearchMin()">{{ 'pg-select.no-result' | locTranslate }}</ng-container>
                    </div>
                    <ng-container *ngFor="let option of visibleOptions; let i = index;" >
                        <div *ngIf="showCategoryBefore(i); let category" class="PGSelect-Select-Dropdown-Options-Category border-bottom">{{category}}</div>
                        <div class="dropdown-item {{isOptionDisabled(option) ? 'disabled' : ''}} {{isOptionSelected(option) ? 'active' : ''}}" 
                            (mousedown)="selectOption(option)">
                            <span [innerHTML]="option.view != null ? option.view : option.text"></span>
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="isIncomplete" class="dropdown-item disabled">...</div>
            </span>
        </div>
    </span>
</span>
