import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnvironmentData, PgContentViewComponent } from 'pg-lib';
import { environment } from '../environments/environment';
import { EfLandingComponent } from './ef-landing/ef-landing.component';
import { EfProductViewComponent } from './ef-product-view/ef-product-view.component';
import { EfShowcaseViewComponent } from './ef-showcase-view/ef-showcase-view.component';

const routes: Routes = [{
    path: 'content/:id',
    component: PgContentViewComponent
}, {
    path: 'showcase',
    component: EfShowcaseViewComponent
}, {
    path: 'product/:resource/:id',
    component: EfProductViewComponent
}, {
    path: 'realm/:realm',
    component: EfLandingComponent
}, {
    path: '',
    pathMatch: 'full',
    component: EfLandingComponent
}, {
    path: '**',
    redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: ((environment as any) as EnvironmentData).AppMode == 'hybrid' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
